import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import WorkloadService from "../../services/WorkloadService";
import schema, { Values } from "./CopyWorkload.schema";

interface RoutingParams {
   workloadId: string;
}

const initialValues = {
   title: "",
   copyCourses: false,
   copyMeetings: false,
   targetSemester: `${moment().year()}`
};

export default function CopyWorkload() {
   const { workloadId } = useParams<RoutingParams>();
   const [values, setValues] = useState<Values>(initialValues);
   const history = useHistory();

   const onSubmit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      const workloadService = new WorkloadService("beta");
      const createdWorkload = await workloadService.copyWorkload(workloadId, {
         ...values,
         targetSemester: Number(values.targetSemester)
      });
      history.push(`/workloads/${createdWorkload.id}`);

      setSubmitting(false);
   };

   useEffect(() => {
      const workloadService = new WorkloadService("beta");
      workloadService.getWorkload(workloadId).then(workload => setValues({ ...initialValues, title: workload.title }));
   }, [workloadId]);

   return (
      <div className="container">
         <Formik initialValues={values} enableReinitialize={true} validationSchema={schema} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
               <Form>
                  <div className="mb-3 row">
                     <h2>Workload kopieren</h2>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="title" className="col-sm-2 col-form-label fw-bold">
                        Titel:
                     </label>
                     <div className="col-sm-10">
                        <Field type="text" className="form-control" id="title" name="title" />
                        <ErrorMessage className="text-danger" name="title" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="targetSemester" className="col-sm-2 col-form-label fw-bold">
                        Studienjahr:
                     </label>
                     <div className="col-sm-10">
                        <Field
                           type="number"
                           min={2021}
                           max={2099}
                           step={1}
                           className="form-control"
                           id="targetSemester"
                           name="targetSemester"
                        />
                        <ErrorMessage className="text-danger" name="targetSemester" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="copyCourses" className="col-sm-2 col-form-label fw-bold">
                        Veranstaltungen kopieren:
                     </label>
                     <div className="col-sm-10">
                        <Field type="checkbox" className="form-check-input" id="copyCourses" name="copyCourses" />
                        <ErrorMessage className="text-danger" name="copyCourses" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="copyMeetings" className="col-sm-2 col-form-label fw-bold">
                        Termine kopieren:
                     </label>
                     <div className="col-sm-10">
                        <Field type="checkbox" className="form-check-input" id="copyMeetings" name="copyMeetings" />
                        <ErrorMessage className="text-danger" name="copyMeetings" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <div className="col">
                        <div className="float-end">
                           <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                              Speichern
                           </button>
                        </div>
                     </div>
                  </div>
               </Form>
            )}
         </Formik>
      </div>
   );
}
