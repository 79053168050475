import * as Yup from "yup";

export interface Values {
   email: string;
   password: string;
}

const schema = Yup.object().shape({
   email: Yup.string().email("Ungültige E-Mail Adresse").required("Erforderliches Feld"),
   password: Yup.string().required("Erforderliches Feld")
});

export default schema;
