import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetStudentUserDto } from "../../models/users/get-student-user.dto";
import { Subject, SubjectName } from "../../models/users/Subjects";
import { UserRole } from "../../models/users/UserRole";
import { useAppSelector } from "../../redux/hooks";
import { selectUserId, selectUserRole } from "../../redux/userInfo/userInfoSlice";
import UserService from "../../services/UserService";

export default function DisplayProfile() {
   const [student, setStudent] = useState<GetStudentUserDto>();
   const role = useAppSelector(selectUserRole);
   const userId = useAppSelector(selectUserId);

   useEffect(() => {
      if (role === UserRole.Student) {
         const userService = new UserService("beta");
         userService.getStudent(userId).then(user => setStudent(user));
      }
   }, [role, userId]);

   if (student) {
      const subjects = student.subjects.filter(s => [Subject.DaZ, Subject.BWS].indexOf(s) < 0);

      return (
         <div className="container">
            <div className="mb-3 row">
               <h2>Profil</h2>
            </div>
            <div className="mb-3 row">
               <label htmlFor="semester" className="col-sm-2 col-form-label fw-bold">
                  Studienjahr:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="semester"
                     value={`${student.semester}/${student.semester + 1}`}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="subject" className="col-sm-2 col-form-label fw-bold">
                  Fächer:
               </label>
               <div className="col-sm-10">
                  {subjects.map((subject, idx) => (
                     <input
                        key={subject}
                        type="text"
                        readOnly
                        className="form-control-plaintext"
                        id={`subject.${idx}`}
                        value={SubjectName[subject]}
                     />
                  ))}
               </div>
            </div>
            <div className="mb-3 row">
               <div className="col">
                  <Link to={"/profile/edit"} className="btn btn-primary float-end">
                     Bearbeiten
                  </Link>
               </div>
            </div>
         </div>
      );
   } else {
      return null;
   }
}
