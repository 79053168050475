import axios from "axios";
import DOMPurify from "dompurify";

import { addError } from "../redux/errors/errorsSlice";
import { store } from "../redux/store";

function xssSanitize(content: any) {
   if (content) {
      if (typeof content === "string") {
         return DOMPurify.sanitize(content);
      }

      for (const key of Object.keys(content)) {
         if (typeof content[key] === "string") {
            content[key] = DOMPurify.sanitize(content[key]);
         }
         if (typeof content[key] === "object") {
            content[key] = xssSanitize(content[key]);
         }
      }
   }

   return content;
}

axios.interceptors.response.use(
   function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      if (response.data) {
         response.data = xssSanitize(response.data);
      }

      return response;
   },
   function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      const message = error.response.data.message || error.toString();

      switch (error.response.status) {
         case 400:
            store.dispatch(addError(message));
            break;
         case 401:
            // if request was not to check permissions
            if (error.response.config.url.indexOf("/auth/permissions") < 0) {
               // unauthorized
               window.location.href = "/#/login";
            }
            break;
         case 403:
            store.dispatch(addError(message));
            break;
         case 404:
            store.dispatch(addError(message));
            break;
      }

      return Promise.reject(error);
   }
);
