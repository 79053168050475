import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { loadTimetable } from "../../redux/timetable/timetableSlice";
import TimelineComponent from "../Timeline/Timeline";
import TimeTable from "../TimeTable/TimeTable";

import styles from "./SemesterPlan.module.scss";

type Mode = "timeline" | "table";

export default function SemesterPlan() {
   const [mode, setMode] = useState<Mode>("timeline");
   const dispatch = useAppDispatch();

   useEffect(() => {
      dispatch(loadTimetable());
   }, [dispatch]);

   return (
      <div className={`container-fluid ${styles.semesterPlanContainer}`}>
         <div className="d-flex justify-content-center mb-3">
            <div className="btn-group btn-group-sm" role="group" aria-label="Auswahl">
               <button
                  type="button"
                  className={`btn ${mode === "timeline" ? "btn-primary" : "btn-outline-primary"}`}
                  onClick={() => setMode("timeline")}
               >
                  Zeitplan
               </button>
               <button
                  type="button"
                  className={`btn ${mode === "table" ? "btn-primary" : "btn-outline-primary"}`}
                  onClick={() => setMode("table")}
               >
                  Tabelle
               </button>
            </div>
         </div>
         {mode === "timeline" && <TimelineComponent />}
         {mode === "table" && <TimeTable />}
      </div>
   );
}
