/* eslint no-unused-vars: "off" */
export enum WorkloadType {
   GeneralMeetings = "generalMeetings",
   Uni = "uni",
   ZfsL = "zfsl",
   School = "school"
}

export const WorkloadTypeName = {
   [WorkloadType.GeneralMeetings]: "Allgemeine Termine",
   [WorkloadType.Uni]: "Uni",
   [WorkloadType.ZfsL]: "ZfsL",
   [WorkloadType.School]: "Schule"
};
