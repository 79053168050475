import * as Yup from "yup";
import { Subject } from "../../models/users/Subjects";

export interface Values {
   mail: string;
   password: string;
   display_name: string;
   subjects: Subject[];
}

const schema = Yup.object().shape({
   mail: Yup.string().email("Ungültige E-Mail Adresse").required("Erforderliches Feld"),
   password: Yup.string().required("Erforderliches Feld"),
   display_name: Yup.string().required("Erforderliches Feld"),
   subjects: Yup.array()
      .of(Yup.mixed<string>().oneOf(Object.values(Subject)))
      .min(2)
      .max(2)
      .required("Erforderliches Feld")
});

export default schema;
