import { RouteComponentProps } from "react-router";
import CopyCourse from "../components/Course/CopyCourse";
import DeleteCourse from "../components/Course/DeleteCourse";
import DisplayCourse from "../components/Course/DisplayCourse";
import EditCourse from "../components/Course/EditCourse";
import ListCourses from "../components/Course/ListCourses";
import DeleteCourseMeeting from "../components/Course/Meeting/DeleteCourseMeeting";
import DisplayCourseMeeting from "../components/Course/Meeting/DisplayCourseMeeting";
import EditCourseMeeting from "../components/Course/Meeting/EditCourseMeeting";
import CreateLBZUser from "../components/LBZUsers/CreateLBZUser";
import ListLBZUsers from "../components/LBZUsers/ListLBZUsers";
import EditLecturer from "../components/Lecturer/EditLecturer";
import ListLecturers from "../components/Lecturer/ListLecturers";
import Login from "../components/Login/Login";
import Logout from "../components/Logout/Logout";
import PageNotFound from "../components/PageNotFound/PageNotFound";
import DeletePersonalMeeting from "../components/PersonalMeeting/DeletePersonalMeeting";
import DisplayPersonalMeeting from "../components/PersonalMeeting/DisplayPersonalMeeting";
import EditPersonalMeeting from "../components/PersonalMeeting/EditPersonalMeeting";
import ListPersonalMeetings from "../components/PersonalMeeting/ListPersonalMeetings";
import DisplayProfile from "../components/Profile/DisplayProfile";
import EditProfile from "../components/Profile/EditProfile";
import Register from "../components/Register/Register";
import SemesterPlan from "../components/SemsterPlan/SemesterPlan";
import StartPage from "../components/StartPage/StartPage";
import CopyWorkload from "../components/Workloads/CopyWorkload";
import DeleteWorkload from "../components/Workloads/DeleteWorkload";
import DisplayWorkload from "../components/Workloads/DisplayWorkload";
import EditWorkload from "../components/Workloads/EditWorkload";
import ListWorkloads from "../components/Workloads/ListWorkloads";

interface RouteConfig {
   path: string;
   exact?: boolean;
   component: Function;
   props?: any;
   breadcrumbs: (
      routeProps: RouteComponentProps<{ [key: string]: string | undefined }>
   ) => { title: string; link?: string }[];
}

const routes: RouteConfig[] = [
   {
      path: "/",
      exact: true,
      component: StartPage,
      breadcrumbs: () => []
   },
   {
      path: "/login",
      component: Login,
      breadcrumbs: () => []
   },
   {
      path: "/logout",
      component: Logout,
      breadcrumbs: () => []
   },
   {
      path: "/register",
      component: Register,
      breadcrumbs: () => []
   },
   {
      path: "/profile/edit",
      component: EditProfile,
      breadcrumbs: () => [{ title: "Profil", link: "/profile" }, { title: "Profil bearbeiten" }]
   },
   {
      path: "/profile",
      component: DisplayProfile,
      breadcrumbs: () => [{ title: "Profil" }]
   },
   {
      path: "/plan",
      component: SemesterPlan,
      breadcrumbs: () => []
   },
   {
      path: "/personalMeetings",
      component: ListPersonalMeetings,
      exact: true,
      breadcrumbs: props => [{ title: "Persönliche Termine" }]
   },
   {
      path: "/personalMeetings/create",
      component: EditPersonalMeeting,
      props: { create: true },
      exact: true,
      breadcrumbs: props => [
         { title: "Persönliche Termine", link: "/personalMeetings" },
         { title: "Persönlicher Termin erstellen" }
      ]
   },
   {
      path: "/personalMeetings/:meetingId/edit",
      component: EditPersonalMeeting,
      breadcrumbs: props => [
         { title: "Persönliche Termine", link: "/personalMeetings" },
         { title: "Persönlicher Termin", link: `/personalMeetings/${props.match.params["meetingId"]}` },
         { title: "Persönlicher Termin bearbeiten" }
      ]
   },
   {
      path: "/personalMeetings/:meetingId/delete",
      component: DeletePersonalMeeting,
      breadcrumbs: props => [
         { title: "Persönliche Termine", link: "/personalMeetings" },
         { title: "Persönlicher Termin", link: `/personalMeetings/${props.match.params["meetingId"]}` },
         { title: "Persönlicher Termin löschen" }
      ]
   },
   {
      path: "/personalMeetings/:meetingId",
      component: DisplayPersonalMeeting,
      breadcrumbs: () => [{ title: "Persönliche Termine", link: "/personalMeetings" }, { title: "Persönlicher Termin" }]
   },
   {
      path: "/courses",
      component: ListCourses,
      exact: true,
      breadcrumbs: () => [{ title: "Veranstaltungen" }]
   },
   {
      path: "/courses/:courseId/meetings/create",
      component: EditCourseMeeting,
      props: { create: true },
      breadcrumbs: props => [
         { title: "Veranstaltungen", link: "/courses" },
         { title: "Veranstaltung", link: `/courses/${props.match.params["courseId"]}` },
         { title: "Veranstaltungstermin erstellen" }
      ]
   },
   {
      path: "/courses/:courseId/meetings/:meetingId/edit",
      component: EditCourseMeeting,
      breadcrumbs: props => [
         { title: "Veranstaltungen", link: "/courses" },
         { title: "Veranstaltung", link: `/courses/${props.match.params["courseId"]}` },
         {
            title: "Veranstaltungstermin",
            link: `/courses/${props.match.params["courseId"]}/meetings/${props.match.params["meetingId"]}`
         },
         { title: "Veranstaltungstermin bearbeiten" }
      ]
   },
   {
      path: "/courses/:courseId/meetings/:meetingId/delete",
      component: DeleteCourseMeeting,
      breadcrumbs: props => [
         { title: "Veranstaltungen", link: "/courses" },
         { title: "Veranstaltung", link: `/courses/${props.match.params["courseId"]}` },
         {
            title: "Veranstaltungstermin",
            link: `/courses/${props.match.params["courseId"]}/meetings/${props.match.params["meetingId"]}`
         },
         { title: "Veranstaltungstermin löschen" }
      ]
   },
   {
      path: "/courses/:courseId/meetings/:meetingId",
      component: DisplayCourseMeeting,
      breadcrumbs: props => [
         { title: "Veranstaltungen", link: "/courses" },
         { title: "Veranstaltung", link: `/courses/${props.match.params["courseId"]}` },
         { title: "Veranstaltungstermin" }
      ]
   },
   {
      path: "/courses/:courseId/copy",
      component: CopyCourse,
      breadcrumbs: props => [
         { title: "Veranstaltungen", link: "/courses" },
         { title: "Veranstaltung", link: `/courses/${props.match.params["courseId"]}` },
         { title: "Veranstaltung kopieren" }
      ]
   },
   {
      path: "/courses/:courseId/edit",
      component: EditCourse,
      breadcrumbs: props => [
         { title: "Veranstaltungen", link: "/courses" },
         { title: "Veranstaltung", link: `/courses/${props.match.params["courseId"]}` },
         { title: "Veranstaltung bearbeiten" }
      ]
   },
   {
      path: "/courses/:courseId/delete",
      component: DeleteCourse,
      breadcrumbs: props => [
         { title: "Veranstaltungen", link: "/courses" },
         { title: "Veranstaltung", link: `/courses/${props.match.params["courseId"]}` },
         { title: "Veranstaltung löschen" }
      ]
   },
   {
      path: "/courses/:courseId",
      component: DisplayCourse,
      breadcrumbs: () => [{ title: "Veranstaltungen", link: "/courses" }, { title: "Veranstaltung" }]
   },
   {
      path: "/workloads",
      component: ListWorkloads,
      exact: true,
      breadcrumbs: () => [{ title: "Workloads" }]
   },
   {
      path: "/workloads/create",
      component: EditWorkload,
      props: { create: true },
      exact: true,
      breadcrumbs: () => [{ title: "Workloads", link: "/workloads" }, { title: "Workload erstellen" }]
   },
   {
      path: "/workloads/:workloadId/courses/create",
      component: EditCourse,
      props: { create: true },
      breadcrumbs: props => [
         { title: "Workloads", link: "/workloads" },
         { title: "Workload", link: `/workloads/${props.match.params["workloadId"]}` },
         { title: "Veranstaltung erstellen" }
      ]
   },
   {
      path: "/workloads/:workloadId/edit",
      component: EditWorkload,
      breadcrumbs: props => [
         { title: "Workloads", link: "/workloads" },
         { title: "Workload", link: `/workloads/${props.match.params["workloadId"]}` },
         { title: "Workload bearbeiten" }
      ]
   },
   {
      path: "/workloads/:workloadId/copy",
      component: CopyWorkload,
      breadcrumbs: props => [
         { title: "Workloads", link: "/workloads" },
         { title: "Workload", link: `/workloads/${props.match.params["workloadId"]}` },
         { title: "Workload kopieren" }
      ]
   },
   {
      path: "/workloads/:workloadId/delete",
      component: DeleteWorkload,
      breadcrumbs: props => [
         { title: "Workloads", link: "/workloads" },
         { title: "Workload", link: `/workloads/${props.match.params["workloadId"]}` },
         { title: "Workload löschen" }
      ]
   },
   {
      path: "/workloads/:workloadId",
      component: DisplayWorkload,
      breadcrumbs: () => [{ title: "Workloads", link: "/workloads" }, { title: "Workload" }]
   },
   {
      path: "/lecturers",
      component: ListLecturers,
      exact: true,
      breadcrumbs: () => [{ title: "Lehrpersonen" }]
   },
   {
      path: "/lecturers/create",
      component: EditLecturer,
      exact: true,
      props: { create: true },
      breadcrumbs: () => [{ title: "Lehrpersonen", link: "/lecturers" }, { title: "Lehrperson" }]
   },
   {
      path: "/lecturers/:lecturerId/edit",
      component: EditLecturer,
      breadcrumbs: () => [{ title: "Lehrpersonen", link: "/lecturers" }, { title: "Lehrperson bearbeiten" }]
   },
   {
      path: "/lbz",
      component: ListLBZUsers,
      exact: true,
      breadcrumbs: () => [{ title: "LBZ" }]
   },
   {
      path: "/lbz/create",
      component: CreateLBZUser,
      exact: true,
      breadcrumbs: () => [{ title: "LBZ", link: "/lbz" }, { title: "LBZ Benutzer*in erstellen" }]
   },
   {
      path: "*",
      component: PageNotFound,
      breadcrumbs: () => []
   }
];

export default routes;
