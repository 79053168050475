import { ErrorMessage, Field, Form, Formik, FormikHelpers, useField, useFormikContext } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { getAllSubjects, getDefaultSubject, Subject, SubjectName } from "../../models/users/Subjects";
import { WorkloadType, WorkloadTypeName } from "../../models/workloads/WorkloadType";
import WorkloadService from "../../services/WorkloadService";
import schema, { Values } from "./EditWorkload.schema";

interface RoutingParams {
   workloadId?: string;
}

interface EditWorkloadProps {
   create?: boolean;
}

const initialValues: Values = {
   title: "",
   subject: getDefaultSubject(),
   semester: `${moment().year()}`,
   type: WorkloadType.Uni
};

function SubjectField(props: any) {
   const {
      values: { type, subject },
      setFieldValue
   } = useFormikContext<Values>();
   const [field] = useField(props);

   useEffect(() => {
      if (type === WorkloadType.School && subject !== Subject.Allgemein) {
         setFieldValue("subject", Subject.Allgemein);
      }
   }, [type, subject, setFieldValue]);

   return <Field {...props} {...field} disabled={type === WorkloadType.School} />;
}

export default function EditPersonalMeeting(props: EditWorkloadProps) {
   const { workloadId } = useParams<RoutingParams>();
   const [workload, setWorkload] = useState<Values>(initialValues);
   const history = useHistory();

   const onSubmit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      const workloadService = new WorkloadService("beta");

      const data = {
         ...values,
         semester: Number(values.semester)
      };

      if (workloadId) {
         await workloadService.patchWorkload(workloadId, data);
         history.push(`/workloads/${workloadId}`);
      } else {
         const createdWorkload = await workloadService.createWorkload(data);
         history.push(`/workloads/${createdWorkload.id}`);
      }

      setSubmitting(false);
   };

   useEffect(() => {
      if (workloadId) {
         const workloadService = new WorkloadService("beta");
         workloadService.getWorkload(workloadId).then(workload => {
            setWorkload({
               ...workload,
               semester: `${workload.semester}`
            });
         });
      }
   }, [workloadId]);

   return (
      <div className="container">
         <Formik initialValues={workload} enableReinitialize={true} validationSchema={schema} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
               <Form>
                  <div className="mb-3 row">
                     <h2>Workload</h2>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="title" className="col-sm-2 col-form-label fw-bold">
                        Titel:
                     </label>
                     <div className="col-sm-10">
                        <Field type="text" className="form-control" id="title" name="title" />
                        <ErrorMessage className="text-danger" name="title" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="type" className="col-sm-2 col-form-label fw-bold">
                        Lernort:
                     </label>
                     <div className="col-sm-10">
                        <Field as="select" className="form-select" id="type" name="type">
                           {Object.values(WorkloadType).map(type => (
                              <option key={type} value={type}>
                                 {WorkloadTypeName[type]}
                              </option>
                           ))}
                        </Field>
                        <ErrorMessage className="text-danger" name="type" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="subjects" className="col-sm-2 col-form-label fw-bold">
                        Fach:
                     </label>
                     <div className="col-sm-10">
                        <SubjectField as="select" className="form-select" id="subject" name="subject">
                           {getAllSubjects().map(subject => (
                              <option key={`subject_${subject}`} value={subject}>
                                 {SubjectName[subject]}
                              </option>
                           ))}
                        </SubjectField>
                        <ErrorMessage className="text-danger" name="subject" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="semester" className="col-sm-2 col-form-label fw-bold">
                        Studienjahr:
                     </label>
                     <div className="col-sm-10">
                        <Field
                           type="number"
                           min={2021}
                           max={2099}
                           step={1}
                           className="form-control"
                           id="semester"
                           name="semester"
                        />
                        <ErrorMessage className="text-danger" name="semester" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <div className="col">
                        <div className="float-end">
                           {workloadId && (
                              <Link to={`/workloads/${workloadId}/delete`} className="btn btn-danger me-1">
                                 Löschen
                              </Link>
                           )}
                           <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                              Speichern
                           </button>
                        </div>
                     </div>
                  </div>
               </Form>
            )}
         </Formik>
      </div>
   );
}
