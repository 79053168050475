import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { GetCourseWithLecturersDto } from "../../models/courses/get-course-with-lecturers.dto";
import { MeetingTypeName } from "../../models/meetings/meetingType";
import { GetStudentUserDto } from "../../models/users/get-student-user.dto";
import { UserRole } from "../../models/users/UserRole";
import { useAppSelector } from "../../redux/hooks";
import { selectUserId, selectUserRole } from "../../redux/userInfo/userInfoSlice";
import CourseService from "../../services/CourseService";
import UserService from "../../services/UserService";

interface RoutingParams {
   courseId: string;
}

export default function DisplayCourse() {
   const { courseId } = useParams<RoutingParams>();
   const [course, setCourse] = useState<GetCourseWithLecturersDto | undefined>();
   const [student, setStudent] = useState<GetStudentUserDto>();
   const role = useAppSelector(selectUserRole);
   const userId = useAppSelector(selectUserId);

   const changeElectiveCourse = (ev: React.MouseEvent, courseId: string, mode: "add" | "remove") => {
      ev.preventDefault();

      if (student && userId) {
         const userService = new UserService("beta");
         let elective_courses = student.elective_courses;

         if (mode === "add") {
            elective_courses = [...student.elective_courses, courseId];
         } else {
            elective_courses = student.elective_courses.filter(c => c !== courseId);
         }

         userService.patchStudent(userId, { elective_courses }).then(student => setStudent(student));
      }

      return false;
   };

   useEffect(() => {
      const courseService = new CourseService("beta");
      courseService.getCourse(courseId).then(course => setCourse(course));
   }, [courseId]);

   useEffect(() => {
      if (userId && role === UserRole.Student) {
         const userService = new UserService("beta");
         userService.getStudent(userId).then(student => setStudent(student));
      }
   }, [userId, role]);

   if (course) {
      return (
         <div className="container">
            <div className="mb-3 row">
               <h2>Veranstaltung</h2>
            </div>
            <div className="mb-3 row">
               <label htmlFor="title" className="col-sm-2 col-form-label fw-bold">
                  Titel:
               </label>
               <div className="col-sm-10">
                  <input type="text" readOnly className="form-control-plaintext" id="title" value={course.title} />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="elective" className="col-sm-2 col-form-label fw-bold">
                  Wahl erforderlich:
               </label>
               <div className="col-sm-10">
                  <input
                     className="form-check-input"
                     type="checkbox"
                     value=""
                     id="elective"
                     readOnly
                     disabled
                     checked={course.elective}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="information" className="col-sm-2 col-form-label fw-bold">
                  Information:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="information"
                     value={course.information}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="link" className="col-sm-2 col-form-label fw-bold">
                  Link:
               </label>
               <div className="col-sm-10">
                  {course.link && (
                     <a href={course.link} target="_blank" rel="noreferrer">
                        {course.link}
                     </a>
                  )}
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="information" className="col-sm-2 col-form-label fw-bold">
                  Lehrpersonen:
               </label>
               <div className="col-sm-10">
                  {course.lecturers.map(lecturer => (
                     <a key={lecturer.id} href={`mailto:${lecturer.mail}`} className="me-3">
                        {lecturer.display_name}
                     </a>
                  ))}
               </div>
            </div>
            <div className="mb-3 row">
               <label className="col-sm-2 col-form-label fw-bold">Termine:</label>
               <div className="col">
                  {(role === UserRole.Admin || role === UserRole.LBZ || role === UserRole.Lecturer) && (
                     <Link to={`/courses/${courseId}/meetings/create`} className="btn btn-primary float-end">
                        Erstellen
                     </Link>
                  )}
               </div>
            </div>
            <div>
               <div className="table-responsive">
                  <table className="table table-striped">
                     <thead>
                        <tr>
                           <th scope="col">Titel</th>
                           <th scope="col">Start-Datum</th>
                           <th scope="col">End-Datum</th>
                           <th scope="col">Start-Zeit</th>
                           <th scope="col">End-Zeit</th>
                           <th scope="col">Typ</th>
                           {(role === UserRole.Admin || role === UserRole.LBZ) && (
                              <>
                                 <th></th>
                                 <th></th>
                              </>
                           )}
                        </tr>
                     </thead>
                     <tbody>
                        {course.meetings.map(meeting => (
                           <tr key={meeting.id}>
                              <td>
                                 <Link to={`/courses/${courseId}/meetings/${meeting.id}`}>{meeting.title}</Link>
                              </td>
                              <td>{moment(meeting.start_date).format("DD.MM.YYYY")}</td>
                              <td>{moment(meeting.end_date).format("DD.MM.YYYY")}</td>
                              <td>{meeting.start_time}</td>
                              <td>{meeting.end_time}</td>
                              <td>{MeetingTypeName[meeting.type]}</td>
                              {(role === UserRole.Admin || role === UserRole.LBZ) && (
                                 <>
                                    <td>
                                       <Link to={`/courses/${courseId}/meetings/${meeting.id}/edit`}>
                                          <i
                                             className="bi-pencil"
                                             role="img"
                                             aria-label="Bearbeiten"
                                             title="Bearbeiten"
                                          ></i>
                                       </Link>
                                    </td>
                                    <td>
                                       <Link to={`/courses/${courseId}/meetings/${meeting.id}/delete`}>
                                          <i className="bi-trash" role="img" aria-label="Löschen" title="Löschen"></i>
                                       </Link>
                                    </td>
                                 </>
                              )}
                           </tr>
                        ))}
                     </tbody>
                  </table>
               </div>
            </div>
            <div className="mb-3 row">
               <div className="col">
                  <div className="float-end">
                     {role === UserRole.Student && (
                        <>
                           {course.elective && student && student.elective_courses.includes(course.id) && (
                              <button
                                 className="btn btn-danger"
                                 onClick={ev => changeElectiveCourse(ev, course.id, "remove")}
                              >
                                 aus Semesterplan entfernen
                              </button>
                           )}
                           {course.elective && student && !student.elective_courses.includes(course.id) && (
                              <button
                                 className="btn btn-success"
                                 onClick={ev => changeElectiveCourse(ev, course.id, "add")}
                              >
                                 zu Semesterplan hinzufügen
                              </button>
                           )}
                        </>
                     )}
                     {(role === UserRole.Admin || role === UserRole.LBZ) && (
                        <Link to={`/courses/${courseId}/delete`} className="btn btn-danger me-1">
                           Löschen
                        </Link>
                     )}
                     {(role === UserRole.Admin || role === UserRole.LBZ || role === UserRole.Lecturer) && (
                        <Link to={`/courses/${courseId}/edit`} className="btn btn-primary">
                           Bearbeiten
                        </Link>
                     )}
                  </div>
               </div>
            </div>
         </div>
      );
   } else {
      return null;
   }
}
