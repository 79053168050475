import { ErrorMessage, Field, FieldArray, Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import CourseService from "../../../services/CourseService";
import { MeetingType, MeetingTypeName } from "../../../models/meetings/meetingType";
import schema, { Values } from "./EditCourseMeeting.schema";

interface RoutingParams {
   courseId: string;
   meetingId?: string;
}

interface EditPersonalMeetingProps {
   create?: boolean;
}

const initialValues: Values = {
   title: "",
   start_date: "",
   end_date: "",
   start_time: "",
   end_time: "",
   type: MeetingType.Single,
   information: "",
   attendees: "",
   link: undefined,
   canceledDates: [],
   creator: ""
};

export default function EditCourseMeeting(props: EditPersonalMeetingProps) {
   const { courseId, meetingId } = useParams<RoutingParams>();
   const [meeting, setMeeting] = useState<Values>(initialValues);
   const history = useHistory();

   const onSubmit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      const courseService = new CourseService("beta");

      // clear if empty to bypass url validation on empty string
      values.link = values.link ? values.link : undefined;

      if (meetingId) {
         await courseService.patchMeeting(courseId, meetingId, values);
         history.push(`/courses/${courseId}/meetings/${meetingId}`);
      } else {
         const createdMeeting = await courseService.createMeeting(courseId, values);
         history.push(`/courses/${courseId}/meetings/${createdMeeting.id}`);
      }

      setSubmitting(false);
   };

   useEffect(() => {
      if (meetingId) {
         const courseService = new CourseService("beta");
         courseService.getMeeting(courseId, meetingId).then(meeting => {
            setMeeting({
               ...meeting,
               start_date: moment(meeting.start_date).format("YYYY-MM-DD"),
               end_date: moment(meeting.end_date).format("YYYY-MM-DD"),
               canceledDates: meeting.canceledDates.map(date => moment(date).format("YYYY-MM-DD")),
               creator: meeting.creator.display_name
            });
         });
      }
   }, [courseId, meetingId]);

   return (
      <div className="container">
         <Formik initialValues={meeting} enableReinitialize={true} validationSchema={schema} onSubmit={onSubmit}>
            {({ isSubmitting, values }) => (
               <Form>
                  <div className="mb-3 row">
                     <h2>Veranstaltungtermin</h2>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="title" className="col-sm-2 col-form-label fw-bold">
                        Titel:
                     </label>
                     <div className="col-sm-10">
                        <Field type="text" className="form-control" id="title" name="title" />
                        <ErrorMessage className="text-danger" name="title" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="start_date" className="col-sm-2 col-form-label fw-bold">
                        Start-Datum:
                     </label>
                     <div className="col-sm-10">
                        <Field type="date" className="form-control" id="start_date" name="start_date" />
                        <ErrorMessage className="text-danger" name="start_date" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="end_date" className="col-sm-2 col-form-label fw-bold">
                        End-Datum:
                     </label>
                     <div className="col-sm-10">
                        <Field type="date" className="form-control" id="end_date" name="end_date" />
                        <ErrorMessage className="text-danger" name="end_date" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="start_time" className="col-sm-2 col-form-label fw-bold">
                        Start-Zeit:
                     </label>
                     <div className="col-sm-10">
                        <Field type="time" className="form-control" id="start_time" name="start_time" />
                        <ErrorMessage className="text-danger" name="start_time" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="end_time" className="col-sm-2 col-form-label fw-bold">
                        End-Zeit:
                     </label>
                     <div className="col-sm-10">
                        <Field type="time" className="form-control" id="end_time" name="end_time" />
                        <ErrorMessage className="text-danger" name="end_time" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="type" className="col-sm-2 col-form-label fw-bold">
                        Meeting-Typ:
                     </label>
                     <div className="col-sm-10">
                        <Field as="select" className="form-select" id="type" name="type">
                           {Object.values(MeetingType).map(type => (
                              <option key={type} value={type}>
                                 {MeetingTypeName[type]}
                              </option>
                           ))}
                        </Field>
                        <ErrorMessage className="text-danger" name="type" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="information" className="col-sm-2 col-form-label fw-bold">
                        Information:
                     </label>
                     <div className="col-sm-10">
                        <Field as="textarea" className="form-control" id="information" name="information" />
                        <ErrorMessage className="text-danger" name="information" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="link" className="col-sm-2 col-form-label fw-bold">
                        Link:
                     </label>
                     <div className="col-sm-10">
                        <Field className="form-control" id="link" name="link" />
                        <ErrorMessage className="text-danger" name="link" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="attendees" className="col-sm-2 col-form-label fw-bold">
                        Teilnehmende:
                     </label>
                     <div className="col-sm-10">
                        <Field className="form-control" id="attendees" name="attendees" />
                        <ErrorMessage className="text-danger" name="attendees" component="div" />
                     </div>
                  </div>
                  {values.type !== MeetingType.Single && (
                     <div className="mb-3 row">
                        <label htmlFor="canceledDates" className="col-sm-2 col-form-label fw-bold">
                           Abgesagte Termine:
                        </label>
                        <div className="col-sm-10">
                           <div className="mb-3">
                              Hier ist es möglich Ausnahmen für einzelne Tage in Terminserien zu hinterlegen.
                           </div>
                           <FieldArray
                              name="canceledDates"
                              render={arrayHelpers => {
                                 if (values.canceledDates && values.canceledDates.length > 0) {
                                    return (
                                       <>
                                          {values.canceledDates.map((canceledDate, index) => (
                                             <div key={index} className="row mx-0 mb-3">
                                                <Field
                                                   type="date"
                                                   className="col form-control me-2"
                                                   name={`canceledDates.${index}`}
                                                />
                                                <button
                                                   type="button"
                                                   className="col-auto btn btn-danger"
                                                   onClick={() => arrayHelpers.remove(index)}
                                                >
                                                   Entfernen
                                                </button>
                                             </div>
                                          ))}
                                          <div className="row mx-0 mb-3">
                                             <button
                                                type="button"
                                                className="col-auto btn btn-success"
                                                onClick={() => arrayHelpers.insert(values.canceledDates.length, "")}
                                             >
                                                weiteren Termin hinzufügen
                                             </button>
                                          </div>
                                       </>
                                    );
                                 } else {
                                    return (
                                       <button
                                          type="button"
                                          className="btn btn-success"
                                          onClick={() => arrayHelpers.push("")}
                                       >
                                          Termin hinzufügen
                                       </button>
                                    );
                                 }
                              }}
                           />
                           <ErrorMessage className="text-danger" name="canceledDates" component="div" />
                        </div>
                     </div>
                  )}
                  {meetingId && (
                     <div className="mb-3 row">
                        <label htmlFor="creator" className="col-sm-2 col-form-label fw-bold">
                           Ersteller*in:
                        </label>
                        <div className="col-sm-10">
                           <input
                              type="text"
                              readOnly
                              className="form-control-plaintext"
                              id="creator"
                              value={meeting.creator}
                           />
                        </div>
                     </div>
                  )}
                  <div className="mb-3 row">
                     <div className="col">
                        <div className="float-end">
                           {meetingId && (
                              <Link
                                 to={`/courses/${courseId}/meetings/${meetingId}/delete`}
                                 className="btn btn-danger me-1"
                              >
                                 Löschen
                              </Link>
                           )}
                           <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                              Speichern
                           </button>
                        </div>
                     </div>
                  </div>
               </Form>
            )}
         </Formik>
      </div>
   );
}
