import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import PersonalMeetingService from "../../services/PersonalMeetingService";

interface RoutingParams {
   meetingId: string;
}

export default function DeletePersonalMeeting() {
   const { meetingId } = useParams<RoutingParams>();
   const history = useHistory();
   const { t } = useTranslation("personalMeetings");

   const submit = () => {
      const personalMeetingService = new PersonalMeetingService("beta");
      personalMeetingService.deletePersonalMeeting(meetingId).then(() => history.push("/personalMeetings"));
   };

   return (
      <div className="container">
         <h2 className="text-center mb-3">{t("confirm_delete")}</h2>
         <div className="text-center pt-3">
            <button type="button" className="btn btn-danger" onClick={() => submit()}>
               {t("delete")}
            </button>
         </div>
      </div>
   );
}
