import axios from "axios";

export default class BaseService {
   private _baseUrl: string;

   constructor(version: string, prefix: string) {
      this._baseUrl = `/api/v${version}/${prefix}`;
   }

   protected async get(endpoint: string): Promise<any> {
      const response = await axios({
         method: "GET",
         url: `${this._baseUrl}/${endpoint}`,
         responseType: "json"
      });

      if (response.status === 200 && response.data) {
         return response.data;
      }
   }

   protected async post(endpoint: string, data: any): Promise<any> {
      const response = await axios({
         method: "POST",
         url: `${this._baseUrl}/${endpoint}`,
         responseType: "json",
         data
      });

      if (response.status === 200 && response.data) {
         return response.data;
      }
   }

   protected async patch(endpoint: string, data: any): Promise<any> {
      const response = await axios({
         method: "PATCH",
         url: `${this._baseUrl}/${endpoint}`,
         responseType: "json",
         data
      });

      if (response.status === 200 && response.data) {
         return response.data;
      }
   }

   protected async delete(endpoint: string): Promise<any> {
      await axios({
         method: "DELETE",
         url: `${this._baseUrl}/${endpoint}`,
         responseType: "json"
      });
   }
}
