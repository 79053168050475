import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { GetWorkloadWithCoursesDto } from "../../models/workloads/get-workload-with-courses.dto";
import CourseService from "../../services/CourseService";
import WorkloadService from "../../services/WorkloadService";

interface RoutingParams {
   courseId: string;
}

export default function CopyCourse() {
   const { courseId } = useParams<RoutingParams>();
   const [title, setTitle] = useState("");
   const [semester, setSemester] = useState(`${moment().year()}`);
   const [workload, setWorkload] = useState("");
   const [copyMeetings, setCopyMeetings] = useState(false);
   const [workloads, setWorkloads] = useState<GetWorkloadWithCoursesDto[]>([]);
   const [isSubmitting, setIsSubmitting] = useState(false);
   const history = useHistory();

   const onSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      setIsSubmitting(true);

      const courseService = new CourseService("beta");
      courseService
         .copyCourse(courseId, {
            title,
            targetWorkload: workload,
            copyMeetings
         })
         .then(createdCourse => {
            history.push(`/courses/${createdCourse.id}`);
         })
         .catch(() => setIsSubmitting(false));

      return false;
   };

   const onTitleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(ev.target.value);
   };

   const onSemesterChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      setSemester(ev.target.value);
   };

   const onWorkloadChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
      setWorkload(ev.target.value);
   };

   const onCopyMeetingsChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
      setCopyMeetings(ev.target.checked);
   };

   useEffect(() => {
      const workloadService = new WorkloadService("beta");
      workloadService.getWorkloads(Number(semester)).then(workloads => {
         setWorkloads(workloads);
         if (workloads.length > 0) {
            setWorkload(workloads[0].id);
         } else {
            setWorkload("");
         }
      });

      const courseService = new CourseService("beta");
      courseService.getCourse(courseId).then(course => setTitle(course.title));
   }, [semester, courseId]);

   return (
      <div className="container">
         <form onSubmit={ev => onSubmit(ev)}>
            <div className="mb-3 row">
               <h2>Veranstaltung kopieren</h2>
            </div>
            <div className="mb-3 row">
               <label htmlFor="title" className="col-sm-2 col-form-label fw-bold">
                  Titel:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     value={title}
                     onChange={onTitleChange}
                     className="form-control"
                     id="title"
                     name="title"
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="semester" className="col-sm-2 col-form-label fw-bold">
                  Semester:
               </label>
               <div className="col-sm-10">
                  <input
                     type="number"
                     min={2021}
                     max={2099}
                     step={1}
                     value={semester}
                     onChange={onSemesterChange}
                     className="form-control"
                     id="semester"
                     name="semester"
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="targetWorkload" className="col-sm-2 col-form-label fw-bold">
                  Workload:
               </label>
               <div className="col-sm-10">
                  <select
                     value={workload}
                     onChange={onWorkloadChange}
                     className="form-select"
                     id="targetWorkload"
                     name="targetWorkload"
                  >
                     {workloads.map(workload => (
                        <option key={workload.id} value={workload.id}>
                           {workload.title}
                        </option>
                     ))}
                  </select>
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="copyMeetings" className="col-sm-2 col-form-label fw-bold">
                  Termine kopieren:
               </label>
               <div className="col-sm-10">
                  <input
                     type="checkbox"
                     className="form-check-input"
                     id="copyMeetings"
                     name="copyMeetings"
                     checked={copyMeetings}
                     onChange={onCopyMeetingsChange}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <div className="col">
                  <div className="float-end">
                     <button disabled={workload === "" || isSubmitting} type="submit" className="btn btn-primary">
                        Speichern
                     </button>
                  </div>
               </div>
            </div>
         </form>
      </div>
   );
}
