import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SubjectName } from "../../models/users/Subjects";
import { UserRole } from "../../models/users/UserRole";
import { GetWorkloadWithCoursesDto } from "../../models/workloads/get-workload-with-courses.dto";
import { WorkloadTypeName } from "../../models/workloads/WorkloadType";
import { useAppSelector } from "../../redux/hooks";
import { selectUserRole } from "../../redux/userInfo/userInfoSlice";
import WorkloadService from "../../services/WorkloadService";

import styles from "./DisplayWorkload.module.scss";

interface RoutingParams {
   workloadId: string;
}

export default function DisplayWorkload() {
   const { workloadId } = useParams<RoutingParams>();
   const [workload, setWorkload] = useState<GetWorkloadWithCoursesDto | undefined>(undefined);
   const role = useAppSelector(selectUserRole);

   const moveCourse = (
      ev: React.MouseEvent<HTMLAnchorElement>,
      courseId: string,
      index: number,
      direction: "up" | "down"
   ) => {
      ev.preventDefault();

      if (workload) {
         const order = workload.courses.map(c => c.id).filter(c => c !== courseId);

         if (direction === "up") {
            order.splice(index - 1, 0, courseId);
         } else {
            order.splice(index + 1, 0, courseId);
         }

         const workloadService = new WorkloadService("beta");
         workloadService
            .patchWorkload(workload.id, {
               ...workload,
               courseOrder: order
            })
            .then(workload => setWorkload(workload));
      }

      return false;
   };

   useEffect(() => {
      const workloadService = new WorkloadService("beta");
      workloadService.getWorkload(workloadId).then(workload => setWorkload(workload));
   }, [workloadId]);

   if (workload) {
      return (
         <div className="container">
            <div className="mb-3 row">
               <h2>Workload</h2>
            </div>
            <div className="mb-3 row">
               <label htmlFor="title" className="col-sm-2 col-form-label fw-bold">
                  Titel:
               </label>
               <div className="col-sm-10">
                  <input type="text" readOnly className="form-control-plaintext" id="title" value={workload.title} />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="type" className="col-sm-2 col-form-label fw-bold">
                  Lernort:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="type"
                     value={WorkloadTypeName[workload.type]}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="subject" className="col-sm-2 col-form-label fw-bold">
                  Fach:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="subject"
                     value={SubjectName[workload.subject]}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="semester" className="col-sm-2 col-form-label fw-bold">
                  Studienjahr:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="semester"
                     value={workload.semester}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label className="col-sm-2 col-form-label fw-bold">Veranstaltungen:</label>
               <div className="col">
                  {(role === UserRole.Admin || role === UserRole.LBZ) && (
                     <Link to={`/workloads/${workloadId}/courses/create`} className="btn btn-primary float-end">
                        Erstellen
                     </Link>
                  )}
               </div>
            </div>
            <div>
               <div className="table-responsive">
                  <table className="table table-striped">
                     <thead>
                        <tr>
                           <th scope="col">Titel</th>
                           <th scope="col">Wahl erforderlich</th>
                           <th></th>
                           <th></th>
                           <th></th>
                           <th></th>
                        </tr>
                     </thead>
                     <tbody>
                        {workload.courses.map((course, index) => (
                           <tr key={course.id}>
                              <td>
                                 <Link to={`/courses/${course.id}`}>{course.title}</Link>
                              </td>
                              <td>
                                 <input
                                    className="form-check-input"
                                    type="checkbox"
                                    readOnly
                                    checked={course.elective}
                                 />
                              </td>
                              <td>
                                 <Link to={`/courses/${course.id}/edit`}>
                                    <i className="bi-pencil" role="img" aria-label="Bearbeiten" title="Bearbeiten"></i>
                                 </Link>
                              </td>
                              <td>
                                 <Link to={`/courses/${course.id}/copy`}>
                                    <i className="bi-files" role="img" aria-label="Kopieren" title="Kopieren"></i>
                                 </Link>
                              </td>
                              <td>
                                 <Link to={`/courses/${course.id}/delete`}>
                                    <i className="bi-trash" role="img" aria-label="Löschen" title="Löschen"></i>
                                 </Link>
                              </td>
                              <td className={styles.arrowCell}>
                                 <a
                                    href="/#"
                                    className={index === 0 ? "invisible" : ""}
                                    onClick={ev => moveCourse(ev, course.id, index, "up")}
                                 >
                                    <i
                                       className="bi-chevron-up"
                                       role="img"
                                       aria-label="Nach oben bewegen"
                                       title="Nach oben bewegen"
                                    ></i>
                                 </a>
                                 <a
                                    href="/#"
                                    className={index === workload.courses.length - 1 ? "invisible" : ""}
                                    onClick={ev => moveCourse(ev, course.id, index, "down")}
                                 >
                                    <i
                                       className="bi-chevron-down"
                                       role="img"
                                       aria-label="Nach unten bewegen"
                                       title="Nach unten bewegen"
                                    ></i>
                                 </a>
                              </td>
                           </tr>
                        ))}
                     </tbody>
                  </table>
               </div>
            </div>
            <div className="mb-3 row">
               <div className="col">
                  <div className="float-end">
                     {(role === UserRole.Admin || role === UserRole.LBZ) && (
                        <>
                           <Link to={`/workloads/${workloadId}/delete`} className="btn btn-danger me-1">
                              Löschen
                           </Link>
                           <Link to={`/workloads/${workloadId}/edit`} className="btn btn-primary">
                              Bearbeiten
                           </Link>
                        </>
                     )}
                  </div>
               </div>
            </div>
         </div>
      );
   } else {
      return null;
   }
}
