import * as Yup from "yup";
import { Subject } from "../../models/users/Subjects";

export interface Values {
   semester: number;
   subjects: Subject[];
}

const schema = Yup.object().shape({
   semester: Yup.number(),
   subjects: Yup.array()
      .of(Yup.mixed<string>().oneOf(Object.values(Subject)))
      .min(4)
      .max(4)
      .required("Erforderliches Feld")
});

export default schema;
