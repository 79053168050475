import { Action, applyMiddleware, configureStore, ThunkAction } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import navigationReducer from "./navigation/navigationSlice";
import errorsReducer from "./errors/errorsSlice";
import timetableReducer from "./timetable/timetableSlice";
import userInfoReducer from "./userInfo/userInfoSlice";

export const store = configureStore({
   reducer: {
      navigation: navigationReducer,
      errors: errorsReducer,
      timetable: timetableReducer,
      userInfo: userInfoReducer
   },
   enhancers: [applyMiddleware(thunkMiddleware)]
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
