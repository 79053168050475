import { CopyCourseDto } from "../models/courses/copy-course.dto";
import { CreateCourseDto } from "../models/courses/create-course.dto";
import { GetCourseWithLecturersDto } from "../models/courses/get-course-with-lecturers.dto";
import { PatchCourseDto } from "../models/courses/patch-course.dto";
import { CreateMeetingDto } from "../models/meetings/create-meeting.dto";
import { GetMeetingDto } from "../models/meetings/get-meeting.dto";
import { PatchMeetingDto } from "../models/meetings/patch-meeting.dto";
import BaseService from "./BaseService";

export default class CourseService extends BaseService {
   constructor(version: string) {
      super(version, "courses");
   }

   public getCourse(courseId: string): Promise<GetCourseWithLecturersDto> {
      return this.get(`${courseId}`);
   }

   public createCourse(data: CreateCourseDto): Promise<GetCourseWithLecturersDto> {
      return this.post("", data);
   }

   public copyCourse(courseId: string, data: CopyCourseDto): Promise<GetCourseWithLecturersDto> {
      return this.post(`${courseId}/copy`, data);
   }

   public patchCourse(courseId: string, data: PatchCourseDto): Promise<GetCourseWithLecturersDto> {
      return this.patch(`${courseId}`, data);
   }

   public deleteCourse(courseId: string): Promise<void> {
      return this.delete(`${courseId}`);
   }

   public getMeeting(courseId: string, meetingId: string): Promise<GetMeetingDto> {
      return this.get(`${courseId}/meetings/${meetingId}`);
   }

   public createMeeting(courseId: string, data: CreateMeetingDto): Promise<GetMeetingDto> {
      return this.post(`${courseId}/meetings`, data);
   }

   public patchMeeting(courseId: string, meetingId: string, data: PatchMeetingDto): Promise<GetMeetingDto> {
      return this.patch(`${courseId}/meetings/${meetingId}`, data);
   }

   public deleteMeeting(courseId: string, meetingId: string): Promise<void> {
      return this.delete(`${courseId}/meetings/${meetingId}`);
   }
}
