import * as Yup from "yup";
import { MeetingType } from "../../../models/meetings/meetingType";

export interface Values {
   title: string;
   start_date: string;
   end_date: string;
   start_time: string;
   end_time: string;
   type: MeetingType;
   information: string;
   attendees: string;
   link?: string;
   canceledDates: string[];
   creator: string;
}

const schema = Yup.object().shape({
   title: Yup.string().required("Erforderliches Feld"),
   start_date: Yup.date().required("Erforderliches Feld"),
   end_date: Yup.date().required("Erforderliches Feld"),
   start_time: Yup.string()
      .matches(/^\d\d:\d\d$/, "Ungültige Uhrzeit: MM:HH")
      .required("Erforderliches Feld"),
   end_time: Yup.string()
      .matches(/^\d\d:\d\d$/, "Ungültige Uhrzeit: MM:HH")
      .required("Erforderliches Feld"),
   type: Yup.mixed<string>().oneOf(Object.values(MeetingType)).required("Erforderliches Feld"),
   information: Yup.string(),
   attendees: Yup.string(),
   link: Yup.string().nullable(),
   canceledDates: Yup.array().of(Yup.date().required("Erforderliches Feld")),
   creator: Yup.string()
});

export default schema;
