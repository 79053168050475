import * as Yup from "yup";

export interface Values {
   title: string;
   copyCourses: boolean;
   copyMeetings: boolean;
   targetSemester: string;
}

const schema = Yup.object().shape({
   copyCourses: Yup.boolean().required("Erforderliches Feld"),
   targetSemester: Yup.number().required("Erforderliches Feld")
});

export default schema;
