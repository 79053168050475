import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getAllSubjects, Subject, SubjectName } from "../../models/users/Subjects";
import { UserRole } from "../../models/users/UserRole";
import { GetWorkloadWithCoursesDto } from "../../models/workloads/get-workload-with-courses.dto";
import { WorkloadType, WorkloadTypeName } from "../../models/workloads/WorkloadType";
import { useAppSelector } from "../../redux/hooks";
import { selectUserRole } from "../../redux/userInfo/userInfoSlice";
import WorkloadService from "../../services/WorkloadService";
import { getCompareFunc, getSortSymbol } from "../../utils/sorting";

import generalStyles from "../../styles/general.module.scss";

export default function ListWorkloads() {
   const [semester, setSemester] = useState(moment().year());
   const [workloads, setWorkloads] = useState<GetWorkloadWithCoursesDto[]>([]);
   const [subject, setSubject] = useState<string | undefined>(undefined);
   const [sortProp, setSortProp] = useState<string>("title");
   const [sortMode, setSortMode] = useState<"asc" | "dsc">("asc");
   const role = useAppSelector(selectUserRole);
   const history = useHistory();

   const onSubjectSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      setSubject(value ? value : undefined);
   };

   const toggleSorting = (ev: React.MouseEvent, property: string) => {
      ev.preventDefault();

      if (property === sortProp) {
         setSortMode(sortMode === "asc" ? "dsc" : "asc");
      } else {
         setSortMode("asc");
      }

      setSortProp(property);
      return false;
   };

   const manageGeneralMeetings = async () => {
      const workloadService = new WorkloadService("beta");
      let generalMeetingWorkload = workloads.find(w => w.type === WorkloadType.GeneralMeetings);

      if (!generalMeetingWorkload) {
         const createdWorkload = await workloadService.createWorkload({
            subject: Subject.Allgemein,
            semester: semester,
            title: "Allgemeine Termine",
            type: WorkloadType.GeneralMeetings
         });

         generalMeetingWorkload = {
            ...createdWorkload,
            courses: []
         };
      }

      history.push(`/workloads/${generalMeetingWorkload.id}`);
   };

   useEffect(() => {
      const workloadService = new WorkloadService("beta");
      workloadService.getWorkloads(semester, subject).then(workloads => setWorkloads(workloads));
   }, [semester, subject]);

   return (
      <div className="container">
         <h2 className="text-center mb-3">Workloads</h2>
         <div className="d-flex justify-content-center align-items-center">
            <button
               type="button"
               className="btn btn-outline-secondary"
               disabled={semester <= 2021}
               onClick={() => setSemester(semester - 1)}
            >
               <i className="bi bi-chevron-left"></i>
            </button>
            <span className="mx-2">
               <b>
                  {semester}/{semester + 1}
               </b>
            </span>
            <button type="button" className="btn btn-outline-secondary" onClick={() => setSemester(semester + 1)}>
               <i className="bi bi-chevron-right"></i>
            </button>
         </div>
         <div className="row my-2">
            <div className="col col-md-6 col-xl-8">
               {(role === UserRole.Admin || role === UserRole.LBZ) && (
                  <>
                     <Link to={"/workloads/create"} className="btn btn-primary me-3">
                        Erstellen
                     </Link>
                     <button onClick={manageGeneralMeetings} className="btn btn-primary">
                        Allgemeine Termine verwalten
                     </button>
                  </>
               )}
            </div>
            <div className="col col-md-6 col-xl-4">
               <div className="input-group">
                  <span className="input-group-text" id="filter-label">
                     Filter
                  </span>
                  <select
                     className="form-select"
                     aria-describedby="filter-label"
                     aria-label="Filter"
                     id="filter"
                     name="filter"
                     onChange={onSubjectSelected}
                  >
                     <option key="" value=""></option>
                     {getAllSubjects().map(subject => (
                        <option key={subject} value={subject}>
                           {SubjectName[subject]}
                        </option>
                     ))}
                  </select>
               </div>
            </div>
         </div>
         <div className="table-responsive">
            <table className="table table-striped">
               <thead>
                  <tr className={generalStyles.sortingHeader}>
                     <th scope="col">
                        <a href="/#" onClick={ev => toggleSorting(ev, "title")}>
                           Titel
                        </a>
                        {getSortSymbol("title", sortProp, sortMode)}
                     </th>
                     <th scope="col">
                        <a href="/#" onClick={ev => toggleSorting(ev, "subject")}>
                           Fach
                        </a>
                        {getSortSymbol("subject", sortProp, sortMode)}
                     </th>
                     <th scope="col">
                        <a href="/#" onClick={ev => toggleSorting(ev, "type")}>
                           Lernort
                        </a>
                        {getSortSymbol("type", sortProp, sortMode)}
                     </th>
                     {(role === UserRole.Admin || role === UserRole.LBZ) && (
                        <>
                           <th></th>
                           <th></th>
                           <th></th>
                        </>
                     )}
                  </tr>
               </thead>
               <tbody>
                  {workloads
                     .filter(w => w.type !== WorkloadType.GeneralMeetings)
                     .sort(getCompareFunc(sortProp, sortMode))
                     .map(workload => (
                        <tr key={workload.id}>
                           <td>
                              <Link to={`/workloads/${workload.id}`}>{workload.title}</Link>
                           </td>
                           <td>{SubjectName[workload.subject]}</td>
                           <td>{WorkloadTypeName[workload.type]}</td>
                           {(role === UserRole.Admin || role === UserRole.LBZ) && (
                              <>
                                 <td>
                                    <Link to={`/workloads/${workload.id}/edit`}>
                                       <i
                                          className="bi-pencil"
                                          role="img"
                                          aria-label="Bearbeiten"
                                          title="Bearbeiten"
                                       ></i>
                                    </Link>
                                 </td>
                                 <td>
                                    <Link to={`/workloads/${workload.id}/copy`}>
                                       <i className="bi-files" role="img" aria-label="Kopieren" title="Kopieren"></i>
                                    </Link>
                                 </td>
                                 <td>
                                    <Link to={`/workloads/${workload.id}/delete`}>
                                       <i className="bi-trash" role="img" aria-label="Löschen" title="Löschen"></i>
                                    </Link>
                                 </td>
                              </>
                           )}
                        </tr>
                     ))}
               </tbody>
            </table>
         </div>
      </div>
   );
}
