import { CopyWorkloadDto } from "../models/workloads/copy-workload.dto";
import { CreateWorkloadDto } from "../models/workloads/create-workload.dto";
import { GetWorkloadWithCoursesDto } from "../models/workloads/get-workload-with-courses.dto";
import { GetWorkloadDto } from "../models/workloads/get-workload.dto";
import { PatchWorkloadDto } from "../models/workloads/patch-workload.dto";
import BaseService from "./BaseService";

export default class WorkloadService extends BaseService {
   constructor(version: string) {
      super(version, "workloads");
   }

   public getWorkloads(semester: number, subject?: string): Promise<GetWorkloadWithCoursesDto[]> {
      return this.get(`?semester=${semester}${subject ? "&subject=" + subject : ""}`);
   }

   public getWorkload(workloadId: string): Promise<GetWorkloadWithCoursesDto> {
      return this.get(`${workloadId}`);
   }

   public createWorkload(data: CreateWorkloadDto): Promise<GetWorkloadDto> {
      return this.post("", data);
   }

   public copyWorkload(workloadId: string, data: CopyWorkloadDto): Promise<GetWorkloadWithCoursesDto> {
      return this.post(`${workloadId}/copy`, data);
   }

   public patchWorkload(workloadId: string, data: PatchWorkloadDto): Promise<GetWorkloadWithCoursesDto> {
      return this.patch(`${workloadId}`, data);
   }

   public deleteWorkload(workloadId: string): Promise<void> {
      return this.delete(`${workloadId}`);
   }
}
