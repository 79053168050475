import * as Yup from "yup";

export interface Values {
   mail: string;
}

const schema = Yup.object().shape({
   mail: Yup.string().email().required("Erforderliches Feld")
});

export default schema;
