import { resources } from "../i18n";

interface NavigationConfigItem {
   key: string;
   title: keyof typeof resources.de.navbar;
   href: string;
}

interface NavigationConfig {
   initial: NavigationConfigItem[];
   admin: NavigationConfigItem[];
   lbz: NavigationConfigItem[];
   lecturer: NavigationConfigItem[];
   student: NavigationConfigItem[];
}

const navigationConfig: NavigationConfig = {
   initial: [
      {
         key: "home",
         title: "home",
         href: "/"
      },
      {
         key: "register",
         title: "register",
         href: "/register"
      },
      {
         key: "login",
         title: "login",
         href: "/login"
      }
   ],
   admin: [
      {
         key: "home",
         title: "home",
         href: "/"
      },
      {
         key: "workloads",
         title: "workloads",
         href: "/workloads"
      },
      {
         key: "courses",
         title: "courses",
         href: "/courses"
      },
      {
         key: "lecturers",
         title: "lecturers",
         href: "/lecturers"
      },
      {
         key: "lbz",
         title: "lbz",
         href: "/lbz"
      },
      {
         key: "logout",
         title: "logout",
         href: "/logout"
      }
   ],
   lbz: [
      {
         key: "home",
         title: "home",
         href: "/"
      },
      {
         key: "workloads",
         title: "workloads",
         href: "/workloads"
      },
      {
         key: "courses",
         title: "courses",
         href: "/courses"
      },
      {
         key: "lecturers",
         title: "lecturers",
         href: "/lecturers"
      },
      {
         key: "logout",
         title: "logout",
         href: "/logout"
      }
   ],
   lecturer: [
      {
         key: "home",
         title: "home",
         href: "/"
      },
      {
         key: "courses",
         title: "courses",
         href: "/courses"
      },
      {
         key: "logout",
         title: "logout",
         href: "/logout"
      }
   ],
   student: [
      {
         key: "home",
         title: "home",
         href: "/"
      },
      {
         key: "plan",
         title: "semesterplan",
         href: "/plan"
      },
      {
         key: "personalMeetings",
         title: "personal_meetings",
         href: "/personalMeetings"
      },
      {
         key: "courses",
         title: "courses",
         href: "/courses"
      },
      {
         key: "profile",
         title: "profile",
         href: "/profile"
      },
      {
         key: "logout",
         title: "logout",
         href: "/logout"
      }
   ]
};

export default navigationConfig;
