import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import navigationConfig from "../../config/navigationConfig";
import { UserRole } from "../../models/users/UserRole";
import type { RootState } from "../store";

interface NavigationState {
   navigationItems:
      | typeof navigationConfig.initial
      | typeof navigationConfig.admin
      | typeof navigationConfig.lbz
      | typeof navigationConfig.lecturer
      | typeof navigationConfig.student;
}

const initialState: NavigationState = {
   navigationItems: navigationConfig.initial
};

export const navigationSlice = createSlice({
   name: "navigation",
   initialState,
   reducers: {
      navigationLogin: (state, action: PayloadAction<UserRole>) => {
         switch (action.payload) {
            case UserRole.Admin:
               state.navigationItems = navigationConfig.admin;
               break;
            case UserRole.LBZ:
               state.navigationItems = navigationConfig.lbz;
               break;
            case UserRole.Lecturer:
               state.navigationItems = navigationConfig.lecturer;
               break;
            case UserRole.Student:
               state.navigationItems = navigationConfig.student;
               break;
         }
      },
      navigationLogout: state => {
         state.navigationItems = initialState.navigationItems;
      }
   }
});

export const { navigationLogin, navigationLogout } = navigationSlice.actions;

export const selectNavigationItems = (state: RootState) => state.navigation.navigationItems;

export default navigationSlice.reducer;
