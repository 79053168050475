import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { GetMeetingDto } from "../../models/meetings/get-meeting.dto";
import PersonalMeetingService from "../../services/PersonalMeetingService";

interface RoutingParams {
   meetingId: string;
}

export default function DisplayPersonalMeeting() {
   const { meetingId } = useParams<RoutingParams>();
   const [meeting, setMeeting] = useState<GetMeetingDto | undefined>(undefined);
   const { t } = useTranslation("personalMeetings");

   useEffect(() => {
      const personalMeetingService = new PersonalMeetingService("beta");
      personalMeetingService.getPersonalMeeting(meetingId).then(meeting => setMeeting(meeting));
   }, [meetingId]);

   if (meeting) {
      return (
         <div className="container">
            <div className="mb-3 row">
               <h2>{t("personal_meeting")}</h2>
            </div>
            <div className="mb-3 row">
               <label htmlFor="title" className="col-sm-2 col-form-label fw-bold">
                  {t("title")}:
               </label>
               <div className="col-sm-10">
                  <input type="text" readOnly className="form-control-plaintext" id="title" value={meeting.title} />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="startDate" className="col-sm-2 col-form-label fw-bold">
                  {t("start_date")}:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="startDate"
                     value={moment(meeting.start_date).format("DD.MM.YYYY")}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="endDate" className="col-sm-2 col-form-label fw-bold">
                  {t("end_date")}:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="endDate"
                     value={moment(meeting.end_date).format("DD.MM.YYYY")}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="startTime" className="col-sm-2 col-form-label fw-bold">
                  {t("start_time")}:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="startTime"
                     value={meeting.start_time}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="endTime" className="col-sm-2 col-form-label fw-bold">
                  {t("end_time")}:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="endTime"
                     value={meeting.end_time}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="type" className="col-sm-2 col-form-label fw-bold">
                  {t("type")}:
               </label>
               <div className="col-sm-10">
                  <input type="text" readOnly className="form-control-plaintext" id="type" value={t(meeting.type)} />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="information" className="col-sm-2 col-form-label fw-bold">
                  {t("information")}:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="information"
                     value={meeting.information}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="link" className="col-sm-2 col-form-label fw-bold">
                  {t("link")}:
               </label>
               <div className="col-sm-10">
                  {meeting.link && (
                     <a href={meeting.link} target="_blank" rel="noreferrer">
                        {meeting.link}
                     </a>
                  )}
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="attendees" className="col-sm-2 col-form-label fw-bold">
                  {t("participants")}:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="attendees"
                     value={meeting.attendees}
                  />
               </div>
            </div>
            {meeting.canceledDates && meeting.canceledDates.length > 0 && (
               <div className="mb-3 row">
                  <label htmlFor="canceledDates" className="col-sm-2 col-form-label fw-bold">
                     {t("cancelled_dates")}:
                  </label>
                  <div className="col-sm-10">
                     {meeting.canceledDates.map(date => (
                        <input
                           type="text"
                           readOnly
                           className="form-control-plaintext"
                           id="canceledDates"
                           value={moment(date).format("DD.MM.YYYY")}
                        />
                     ))}
                  </div>
               </div>
            )}
            <div className="mb-3 row">
               <label htmlFor="creator" className="col-sm-2 col-form-label fw-bold">
                  {t("creator")}:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="creator"
                     value={meeting.creator.display_name}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <div className="col">
                  <div className="float-end">
                     <Link to={`/personalMeetings/${meetingId}/delete`} className="btn btn-danger me-1">
                        {t("delete")}
                     </Link>
                     <Link to={`/personalMeetings/${meetingId}/edit`} className="btn btn-primary">
                        {t("edit")}
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      );
   } else {
      return null;
   }
}
