import { removeError, selectErrorItems } from "../../redux/errors/errorsSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

export default function ErrorBar() {
   const errors = useAppSelector(selectErrorItems);
   const dispatch = useAppDispatch();

   const remove = (key: string) => {
      dispatch(removeError(key));
   };

   return (
      <div>
         {errors.map(error => {
            return (
               <div key={error.key} className="alert alert-danger  alert-dismissible" role="alert" id="liveAlert">
                  {error.text}
                  <button
                     type="button"
                     className="btn-close"
                     aria-label="Schließen"
                     title="Schließen"
                     onClick={() => remove(error.key)}
                  ></button>
               </div>
            );
         })}
      </div>
   );
}
