import { Link } from "react-router-dom";

export interface BreadcrumbsProps {
   breadcrumbs: { title: string; link?: string }[];
}

export default function Breadcrumbs(props: BreadcrumbsProps) {
   return (
      <nav aria-label="breadcrumb">
         <ol className="breadcrumb">
            {props.breadcrumbs.map((bc, idx) => {
               if (idx !== props.breadcrumbs.length - 1 && bc.link) {
                  return (
                     <li className="breadcrumb-item" key={idx}>
                        <Link to={bc.link}>{bc.title}</Link>
                     </li>
                  );
               } else if (idx === props.breadcrumbs.length - 1) {
                  return (
                     <li className="breadcrumb-item active" aria-current="page" key={idx}>
                        {bc.title}
                     </li>
                  );
               }

               return null;
            })}
         </ol>
      </nav>
   );
}
