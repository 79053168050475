import * as Yup from "yup";

export interface Values {
   title: string;
   information: string;
   elective: boolean;
   link?: string;
   workload: string;
   lecturers: string[];
}

const schema = Yup.object().shape({
   title: Yup.string().required("Erforderliches Feld"),
   information: Yup.string(),
   elective: Yup.boolean(),
   link: Yup.string().nullable(),
   workload: Yup.string().required("Erforderliches Feld"),
   lecturers: Yup.array().of(Yup.string().required("Erforderliches Feld"))
});

export default schema;
