import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetUserDto } from "../../models/users/get-user.dto";
import { UserRole } from "../../models/users/UserRole";
import { useAppSelector } from "../../redux/hooks";
import { selectUserRole } from "../../redux/userInfo/userInfoSlice";
import UserService from "../../services/UserService";

export default function ListLBZUser() {
   const [lbzUsers, setLbzUsers] = useState<GetUserDto[]>([]);
   const role = useAppSelector(selectUserRole);

   const changeUserStatus = (ev: React.MouseEvent, userId: string, mode: "activate" | "deactivate") => {
      ev.preventDefault();

      const userService = new UserService("beta");
      userService
         .patchUserStatus(userId, {
            active: mode === "activate"
         })
         .then(() => {
            userService.getLBZ().then(lbz => setLbzUsers(lbz));
         });

      return false;
   };

   useEffect(() => {
      const userService = new UserService("beta");
      userService.getLBZ().then(lbz => setLbzUsers(lbz));
   }, []);

   return (
      <div className="container">
         <h2 className="text-center mb-3">LBZ</h2>
         <div className="row mt-2 mb-3">
            <div className="col col-md-6 col-xl-8">
               {role === UserRole.Admin && (
                  <Link to={"/lbz/create"} className="btn btn-primary">
                     Erstellen
                  </Link>
               )}
            </div>
         </div>
         <div className="table-responsive">
            <table className="table table-striped">
               <thead>
                  <tr>
                     <th scope="col">Anzeige-Name</th>
                     <th scope="col">E-Mail</th>
                     <th></th>
                  </tr>
               </thead>
               <tbody>
                  {lbzUsers.map(user => (
                     <tr key={user.id} className="align-middle">
                        <td>{user.display_name}</td>
                        <td>{user.mail}</td>
                        <td>
                           {user.active ? (
                              <button
                                 className="btn btn-danger btn-sm"
                                 onClick={ev => changeUserStatus(ev, user.id, "deactivate")}
                              >
                                 Deaktivieren
                              </button>
                           ) : (
                              <button
                                 className="btn btn-success btn-sm"
                                 onClick={ev => changeUserStatus(ev, user.id, "activate")}
                              >
                                 Aktivieren
                              </button>
                           )}
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </div>
      </div>
   );
}
