import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GetMeetingDto } from "../../models/meetings/get-meeting.dto";
import PersonalMeetingService from "../../services/PersonalMeetingService";

import generalStyles from "../../styles/general.module.scss";
import { getCompareFunc, getSortSymbol } from "../../utils/sorting";

export default function ListPersonalMeetings() {
   const [meetings, setMeetings] = useState<GetMeetingDto[]>([]);
   const [sortProp, setSortProp] = useState<string>("start_date");
   const [sortMode, setSortMode] = useState<"asc" | "dsc">("asc");
   const { t } = useTranslation("personalMeetings");

   const toggleSorting = (ev: React.MouseEvent, property: string) => {
      ev.preventDefault();

      if (property === sortProp) {
         setSortMode(sortMode === "asc" ? "dsc" : "asc");
      } else {
         setSortMode("asc");
      }

      setSortProp(property);
      return false;
   };

   useEffect(() => {
      const personalMeetingService = new PersonalMeetingService("beta");
      personalMeetingService.getPersonalMeetings().then(meetings => setMeetings(meetings));
   }, []);

   return (
      <div className="container">
         <h2 className="text-center mb-3">{t("personal_meetings")}</h2>
         <div className="mb-1 row">
            <div className="col">
               <Link to={"/personalMeetings/create"} className="btn btn-primary float-end">
                  {t("create")}
               </Link>
            </div>
         </div>
         <div className="table-responsive">
            <table className="table table-striped">
               <thead>
                  <tr className={generalStyles.sortingHeader}>
                     <th scope="col">
                        <a href="/#" onClick={ev => toggleSorting(ev, "title")}>
                           {t("title")}
                        </a>
                        {getSortSymbol("title", sortProp, sortMode)}
                     </th>
                     <th scope="col">
                        <a href="/#" onClick={ev => toggleSorting(ev, "start_date")}>
                           {t("start_date")}
                        </a>
                        {getSortSymbol("start_date", sortProp, sortMode)}
                     </th>
                     <th scope="col">
                        <a href="/#" onClick={ev => toggleSorting(ev, "end_date")}>
                           {t("end_date")}
                        </a>
                        {getSortSymbol("end_date", sortProp, sortMode)}
                     </th>
                     <th scope="col">
                        <a href="/#" onClick={ev => toggleSorting(ev, "start_time")}>
                           {t("start_time")}
                        </a>
                        {getSortSymbol("start_time", sortProp, sortMode)}
                     </th>
                     <th scope="col">
                        <a href="/#" onClick={ev => toggleSorting(ev, "end_time")}>
                           {t("end_time")}
                        </a>
                        {getSortSymbol("end_time", sortProp, sortMode)}
                     </th>
                     <th scope="col">{t("type")}</th>
                     <th></th>
                     <th></th>
                  </tr>
               </thead>
               <tbody>
                  {meetings.sort(getCompareFunc(sortProp, sortMode)).map(meeting => (
                     <tr key={meeting.id}>
                        <td>
                           <Link to={`/personalMeetings/${meeting.id}`}>{meeting.title}</Link>
                        </td>
                        <td>{moment(meeting.start_date).format("DD.MM.YYYY")}</td>
                        <td>{moment(meeting.end_date).format("DD.MM.YYYY")}</td>
                        <td>{meeting.start_time}</td>
                        <td>{meeting.end_time}</td>
                        <td>{t(meeting.type)}</td>
                        <td>
                           <Link to={`/personalMeetings/${meeting.id}/edit`}>
                              <i className="bi-pencil" role="img" aria-label={t("edit")} title={t("edit")}></i>
                           </Link>
                        </td>
                        <td>
                           <Link to={`/personalMeetings/${meeting.id}/delete`}>
                              <i className="bi-trash" role="img" aria-label={t("delete")} title={t("delete")}></i>
                           </Link>
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </div>
      </div>
   );
}
