import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { getSubjects, Subject, SubjectName } from "../../models/users/Subjects";
import { UserRole } from "../../models/users/UserRole";
import { useAppSelector } from "../../redux/hooks";
import { selectUserId, selectUserRole } from "../../redux/userInfo/userInfoSlice";
import UserService from "../../services/UserService";
import schema, { Values } from "./EditProfile.schema";

const initialValues: Values = {
   semester: moment().year(),
   subjects: []
};

export default function EditProfile() {
   const [student, setStudent] = useState<Values>(initialValues);
   const role = useAppSelector(selectUserRole);
   const userId = useAppSelector(selectUserId);
   const history = useHistory();

   const onSubmit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      const userService = new UserService("beta");

      await userService.patchStudent(userId, {
         semester: values.semester,
         subjects: values.subjects
      });

      history.push("/profile");
      setSubmitting(false);
   };

   useEffect(() => {
      if (role === UserRole.Student) {
         const userService = new UserService("beta");
         userService.getStudent(userId).then(user => setStudent(user));
      }
   }, [role, userId]);

   if (student) {
      return (
         <div className="container">
            <Formik initialValues={student} enableReinitialize={true} validationSchema={schema} onSubmit={onSubmit}>
               {({ isSubmitting, values }) => (
                  <Form>
                     <div className="mb-3 row">
                        <h2>Profil</h2>
                     </div>
                     <div className="mb-3 row">
                        <label htmlFor="semester" className="col-sm-2 col-form-label fw-bold">
                           Studienjahr:
                        </label>
                        <div className="col-sm-10">
                           <Field
                              type="number"
                              min={2021}
                              max={2099}
                              step={1}
                              className="form-control"
                              id="semester"
                              name="semester"
                           />
                           <ErrorMessage className="text-danger" name="semester" component="div" />
                        </div>
                     </div>
                     <div className="mb-3 row">
                        <label htmlFor="subject" className="col-sm-2 col-form-label fw-bold">
                           Fächer:
                        </label>
                        <div className="col-sm-10">
                           {student.subjects.map((subject, idx) => {
                              if ([Subject.DaZ, Subject.BWS].indexOf(subject) < 0) {
                                 return (
                                    <div key={`subject_${idx}`} className="mb-3">
                                       <Field
                                          as="select"
                                          className="form-select"
                                          id={`subjects[${idx}]`}
                                          name={`subjects[${idx}]`}
                                       >
                                          {getSubjects().map(s => (
                                             <option key={`subject_${s}`} value={s}>
                                                {SubjectName[s]}
                                             </option>
                                          ))}
                                       </Field>
                                       <ErrorMessage className="text-danger" name="subjects[0]" component="div" />
                                    </div>
                                 );
                              } else {
                                 return null;
                              }
                           })}
                        </div>
                     </div>
                     <div className="mb-3 row">
                        <div className="col">
                           <button disabled={isSubmitting} type="submit" className="btn btn-primary float-end">
                              Speichern
                           </button>
                        </div>
                     </div>
                  </Form>
               )}
            </Formik>
         </div>
      );
   } else {
      return null;
   }
}
