//TODO: define subjects
/* eslint no-unused-vars: "off" */
export enum Subject {
   // those subjects not available as first or second subject
   Allgemein = "Allgemein",
   BWS = "BWS",
   DaZ = "DaZ",

   Informatik = "Informatik",
   Mathematik = "Mathematik",
   Deutsch = "Deutsch"
}

const nonStandardSubjects = [Subject.Allgemein, Subject.BWS, Subject.DaZ];

export function getAllSubjects() {
   return Object.values(Subject).sort((a, b) => a.localeCompare(b));
}

export function getSubjects() {
   /* skip  "Allgemein", "BWS" and "DaZ" */
   return getAllSubjects().filter(s => nonStandardSubjects.indexOf(s) === -1);
}

export function getDefaultSubject() {
   return getSubjects()[0];
}

export const SubjectName = {
   "": "",
   [Subject.Allgemein]: "Allgemein",
   [Subject.BWS]: "BWS",
   [Subject.DaZ]: "DSSZ / DaZ",
   [Subject.Informatik]: "Informatik",
   [Subject.Mathematik]: "Mathematik",
   [Subject.Deutsch]: "Deutsch"
};
