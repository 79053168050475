import { useHistory } from "react-router-dom";
import { clearErrors } from "../../redux/errors/errorsSlice";
import { useAppDispatch } from "../../redux/hooks";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import ErrorBar from "../ErrorBar/ErrorBar";

interface ILayoutProps {
   children: React.ReactNode;
   breadcrumbs: { title: string; link?: string }[];
}

export default function Layout({ children, breadcrumbs }: ILayoutProps) {
   const history = useHistory();
   const dispatch = useAppDispatch();

   history.listen(() => {
      dispatch(clearErrors());
   });

   return (
      <div className="container-fluid mt-1 d-flex flex-grow-1 flex-column">
         <ErrorBar />
         {breadcrumbs && breadcrumbs.length > 0 && <Breadcrumbs breadcrumbs={breadcrumbs} />}
         <main className="container-fluid mt-2 flex-fill">{children}</main>
      </div>
   );
}
