import { GetTimelineDto } from "../../models/timeline/get-timeline.dto";
import { WorkloadType } from "../../models/workloads/WorkloadType";
import TimelineResource from "./TimelineResource";

export default function getResources(timeline: GetTimelineDto) {
   const resources: TimelineResource[] = [
      {
         name: "Persönliche Meetings",
         id: "res_pers_meetings",
         resourceType: "personal"
      }
   ];

   timeline.workloads.forEach(workload => {
      if (workload.courses.length > 0) {
         resources.push({
            name: workload.title,
            id: `res_${workload.id}`,
            resourceType: "workload",
            workloadId: workload.id
         });

         if (workload.type !== WorkloadType.GeneralMeetings) {
            workload.courses.forEach(course => {
               resources.push({
                  name: course.title,
                  id: `res_${course.id}`,
                  parent: `res_${workload.id}`,
                  resourceType: "course",
                  workloadId: workload.id,
                  courseId: course.id
               });
            });
         }
      }
   });

   return resources;
}
