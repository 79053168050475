import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Collapse } from "bootstrap";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { navigationLogin, selectNavigationItems } from "../../redux/navigation/navigationSlice";
import navigationConfig from "../../config/navigationConfig";
import AuthService from "../../services/AuthService";
import { setUser } from "../../redux/userInfo/userInfoSlice";
import { resources } from "../../i18n";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export default function Navbar() {
   const authService = new AuthService("beta");

   const navigationItems = useAppSelector(selectNavigationItems);
   const location = useLocation();
   const dispatch = useAppDispatch();
   const [toggle, setToggle] = useState(false);
   const [language, setLanguage] = useState(i18next.language);
   const { t } = useTranslation("navbar");

   const onSetLanguage = (ev: React.MouseEvent, lng: string) => {
      ev.preventDefault();
      i18next.changeLanguage(lng);
      setLanguage(lng);
      return false;
   };

   useEffect(() => {
      i18next.on("languageChanged", options => setLanguage(options));
   });

   useEffect(() => {
      const collapseContent = document.getElementById("navbarSupportedContent");
      const bsCollapse = new Collapse(collapseContent!, { toggle: false });
      toggle ? bsCollapse.show() : bsCollapse.hide();
   });

   /* eslint-disable react-hooks/exhaustive-deps */
   useEffect(() => {
      if (!navigationItems || navigationItems === navigationConfig.initial) {
         authService
            .getPermissions()
            .then(permissions => {
               dispatch(navigationLogin(permissions.role));
               dispatch(setUser(permissions));
            })
            .catch(() => {});
      }
   }, [navigationItems]);

   return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
         <div className="container-fluid">
            <a className="navbar-brand" href="/#/">
               RWTH Praxissemester-Planer
            </a>
            <button
               onClick={() => setToggle(toggle => !toggle)}
               className="navbar-toggler"
               type="button"
               data-bs-target="#navbarSupportedContent"
               aria-controls="navbarSupportedContent"
               aria-expanded="false"
               aria-label="Navigation ein-/ausblenden"
               title="Navigation ein-/ausblenden"
            >
               <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
               <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  {navigationItems.map(item => {
                     const isCurrent = location.pathname === item.href;
                     let link = (
                        <Link className="nav-link" to={item.href}>
                           {t(`${item.title}`)}
                        </Link>
                     );

                     if (isCurrent) {
                        link = (
                           <Link className="nav-link active" aria-current="page" to={item.href}>
                              {t(`${item.title}`)}
                           </Link>
                        );
                     }

                     return (
                        <li key={item.key} className="nav-item">
                           {link}
                        </li>
                     );
                  })}
               </ul>
               <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  {[...Object.keys(resources)].map(lng => (
                     <li key={lng} className="nav-item">
                        <a
                           href="/#"
                           onClick={ev => onSetLanguage(ev, lng)}
                           className={`nav-link ${language === lng ? "active text-decoration-underline" : ""}`}
                        >
                           {lng.toUpperCase()}
                        </a>
                     </li>
                  ))}
               </ul>
            </div>
         </div>
      </nav>
   );
}
