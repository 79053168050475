/* eslint-disable max-len */
import { GanttChart } from "ibm-gantt-chart-react";
import getResources from "./getResources";
import getActivities from "./getActivities";
import getReservations from "./getReservations";
import moment from "moment";
import TimelineActivity from "./TimelineActivity";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { loadTimetable, selectTimetable } from "../../redux/timetable/timetableSlice";
import TimelineResource from "./TimelineResource";
import { useHistory } from "react-router-dom";
import { WorkloadType } from "../../models/workloads/WorkloadType";

import styles from "../../styles/rwth.colors.module.scss";

import "ibm-gantt-chart/dist/ibm-gantt-chart.css";
import "./Timeline.scss";

export default function TimelineComponent() {
   const dispatch = useAppDispatch();
   const timetableData = useAppSelector(selectTimetable);
   const history = useHistory();

   // set locale for gantt
   // https://github.com/IBM/gantt-chart/issues/17
   // https://github.com/IBM/gantt-chart/blob/master/packages/ibm-gantt-chart/src/jquery/timeline/timeline.js
   const untypedWindow = window as any;
   if (untypedWindow.Gantt) {
      untypedWindow.Gantt.utils.setIntl({
         ...untypedWindow.Gantt.utils.getIntl(),
         locale: "de"
      });
   }

   const data = {
      all: () => Promise.resolve(timetableData),
      resources: {
         data: getResources,
         parent: "parent",
         name: "name",
         id: "id"
      },
      reservations: {
         data: getReservations,
         activity: "activity",
         resource: "resource"
      },
      activities: {
         data: getActivities,
         start: "start",
         end: "end",
         name: "name",
         parent: "parent"
      },
      constraints: {
         data: [],
         from: "from",
         to: "to",
         type: "type"
      }
   };

   const config = {
      data: data,
      timeTable: {
         renderer: {
            tooltipProperties: (activity: any) => [
               "Start",
               moment(activity.start).utc().format("DD.MM.YYYY HH:mm"),
               "Ende",
               moment(activity.end).utc().format("DD.MM.YYYY HH:mm")
            ],
            background: (activity: TimelineActivity) => {
               switch (activity.workloadType) {
                  case WorkloadType.Uni:
                     return activity.activityType === "meeting" ? styles.rwthBlue50 : styles.rwthBlue;
                  case WorkloadType.ZfsL:
                     return activity.activityType === "meeting" ? styles.rwthOrange50 : styles.rwthOrange;
                  case WorkloadType.School:
                     return activity.activityType === "meeting" ? styles.rwthMaygreen50 : styles.rwthMaygreen;
                  default:
                     return styles.rwthRed;
               }
            },
            color: "automatic"
         }
      },
      selection: {
         // see ibm-gantt-chart/src/panel/ganttpanel.js for registering other selection handlers
         selectActivities: function (selections: any[], selection: any) {
            const activity: TimelineActivity = selection.getData();

            switch (activity.activityType) {
               case "meeting":
                  switch (activity.workloadType) {
                     case "personal":
                        history.push(`/personalMeetings/${activity.meetingId}`);
                        break;
                     default:
                        history.push(`/courses/${activity.courseId}/meetings/${activity.meetingId}`);
                        break;
                  }
                  break;
               case "workload":
                  break;
            }
         },
         selectResources: function (selections: any[], selection: any) {
            const resource: TimelineResource = selection.getData();

            switch (resource.resourceType) {
               case "course":
                  history.push(`/courses/${resource.courseId}`);
                  break;
            }
         }
      },
      toolbar: [
         "title",
         "separator",
         {
            type: "button",
            text: "Aktualisieren",
            fontIcon: "fa fa-refresh fa-lg",
            onclick: function (ctx: any) {
               dispatch(loadTimetable());
            }
         },
         {
            type: "button",
            text: "An Inhalt anpassen",
            svg: {
               width: 20,
               height: 20,
               viewBox: "0 0 24 24",
               paths: [
                  "m9,3c3.309,0 6,2.691 6,6s-2.691,6 -6,6s-6,-2.691 -6,-6s2.691,-6 6,-6m0,-2a8,8 0 1 0 0,16a8,8 0 0 0 0,-16zm6,17l3,-3l5,5l-3,3l-5,-5z",
                  "m5.067202,10.936161l1.794565,-1.677985l-1.794565,-1.677997l1.025685,-0.958955l2.820467,2.636953l-2.820467,2.637771",
                  "m13.211615,7.601521l-1.74349,1.712784l1.74349,1.712783l-0.996386,0.979422l-2.739875,-2.692204l2.739875,-2.69241"
               ]
            },
            onclick: function (ctx: any) {
               ctx.gantt.fitToContent();
            }
         },
         {
            type: "button",
            text: "Vergrößern",
            svg: {
               width: 20,
               height: 20,
               viewBox: "0 0 24 24",
               paths: [
                  "M9 1a8 8 0 1 0 0 16A8 8 0 0 0 9 1zm0 14c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zM15 18l3-3 5 5-3 3z",
                  "M10 5H8v3H5v2h3v3h2v-3h3V8h-3z"
               ]
            },
            onclick: function (ctx: any) {
               ctx.gantt.zoomIn();
            }
         },
         {
            type: "button",
            text: "Verkleinern",
            svg: {
               width: 20,
               height: 20,
               /*margin: '-2px 0 0 0',*/
               viewBox: "0 0 24 24",
               paths: [
                  "M9 3c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6m0-2a8 8 0 1 0 0 16A8 8 0 0 0 9 1zM14.999 18l3-3 5 5-3 3z",
                  "M9 3c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6m0-2a8 8 0 1 0 0 16A8 8 0 0 0 9 1z",
                  "M5 8h8v2H5z"
               ]
            },
            onclick: function (ctx: any) {
               ctx.gantt.zoomOut();
            }
         }
      ],
      zoomFactor: 0.5,
      title: "Semesterplan"
   };

   if (timetableData) {
      return <GanttChart config={config} />;
   } else {
      return null;
   }
}
