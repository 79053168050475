import UserPermissions from "../models/users/UserPermissions";
import BaseService from "./BaseService";

export default class AuthService extends BaseService {
   constructor(version: string) {
      super(version, "auth");
   }

   public async login(mail: string, password: string): Promise<UserPermissions> {
      await this.post("login", {
         mail,
         password
      });

      return this.getPermissions();
   }

   public async getPermissions(): Promise<UserPermissions> {
      const permissions = await this.get("permissions");
      return permissions;
   }
}
