import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetLecturerUserDto } from "../../models/users/get-lecturer-user.dto";
import { SubjectName } from "../../models/users/Subjects";
import { UserRole } from "../../models/users/UserRole";
import { useAppSelector } from "../../redux/hooks";
import { selectUserRole } from "../../redux/userInfo/userInfoSlice";
import UserService from "../../services/UserService";

export default function ListLecturers() {
   const [lecturers, setLecturers] = useState<GetLecturerUserDto[]>([]);
   const [search, setSearch] = useState<string>("");
   const role = useAppSelector(selectUserRole);

   const onSearchChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
      const value = ev.target.value;
      setSearch(value);
   };

   const changeUserStatus = (ev: React.MouseEvent, userId: string, mode: "activate" | "deactivate") => {
      ev.preventDefault();

      const userService = new UserService("beta");
      userService
         .patchUserStatus(userId, {
            active: mode === "activate"
         })
         .then(() => {
            userService.getLecturers(search).then(lecturers => setLecturers(lecturers));
         });

      return false;
   };

   useEffect(() => {
      const userService = new UserService("beta");
      userService.getLecturers(search).then(lecturers => setLecturers(lecturers));
   }, [search]);

   return (
      <div className="container">
         <h2 className="text-center mb-3">Lehrpersonen</h2>
         <div className="row mt-2 mb-3">
            <div className="col col-md-6 col-xl-8">
               {(role === UserRole.Admin || role === UserRole.LBZ) && (
                  <Link to={"/lecturers/create"} className="btn btn-primary">
                     Erstellen
                  </Link>
               )}
            </div>
            <div className="col col-md-6 col-xl-4">
               <div className="input-group">
                  <span className="input-group-text" id="search-label">
                     Suchen
                  </span>
                  <input
                     type="text"
                     className="form-control"
                     aria-describedby="search-label"
                     aria-label="Suchen"
                     id="search"
                     name="search"
                     onChange={onSearchChanged}
                  />
               </div>
            </div>
         </div>
         <div className="table-responsive">
            <table className="table table-striped">
               <thead>
                  <tr>
                     <th scope="col">Anzeige-Name</th>
                     <th scope="col">E-Mail</th>
                     <th scope="col">Fach</th>
                     <th></th>
                     <th></th>
                  </tr>
               </thead>
               <tbody>
                  {lecturers.map(lecturer => (
                     <tr key={lecturer.id} className="align-middle">
                        <td>{lecturer.display_name}</td>
                        <td>{lecturer.mail}</td>
                        <td>{lecturer.subjects.map(s => SubjectName[s]).join(", ")}</td>
                        <td>
                           <Link to={`/lecturers/${lecturer.id}/edit`}>
                              <i className="bi-pencil" role="img" aria-label="Bearbeiten" title="Bearbeiten"></i>
                           </Link>
                        </td>
                        <td>
                           {lecturer.active ? (
                              <button
                                 className="btn btn-danger btn-sm"
                                 onClick={ev => changeUserStatus(ev, lecturer.id, "deactivate")}
                              >
                                 Deaktivieren
                              </button>
                           ) : (
                              <button
                                 className="btn btn-success btn-sm"
                                 onClick={ev => changeUserStatus(ev, lecturer.id, "activate")}
                              >
                                 Aktivieren
                              </button>
                           )}
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </div>
      </div>
   );
}
