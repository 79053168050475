import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useHistory } from "react-router";
import UserService from "../../services/UserService";
import schema, { Values } from "./CreateLBZUser.schema";

const initialValues: Values = {
   mail: ""
};

export default function EditLecturer() {
   const history = useHistory();

   const onSubmit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      const userService = new UserService("beta");
      await userService.createLBZ(values);

      history.push("/lbz");
      setSubmitting(false);
   };

   return (
      <div className="container">
         <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={schema} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
               <Form>
                  <div className="mb-3 row">
                     <h2>LBZ</h2>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="mail" className="col-sm-2 col-form-label fw-bold">
                        E-Mail:
                     </label>
                     <div className="col-sm-10">
                        <Field type="text" className="form-control" id="mail" name="mail" />
                        <ErrorMessage className="text-danger" name="mail" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <div className="col">
                        <div className="float-end">
                           <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                              Speichern
                           </button>
                        </div>
                     </div>
                  </div>
               </Form>
            )}
         </Formik>
      </div>
   );
}
