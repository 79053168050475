import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetCourseDto } from "../../models/courses/get-course.dto";
import { GetStudentUserDto } from "../../models/users/get-student-user.dto";
import { getAllSubjects, Subject, SubjectName } from "../../models/users/Subjects";
import { UserRole } from "../../models/users/UserRole";
import { GetWorkloadWithCoursesDto } from "../../models/workloads/get-workload-with-courses.dto";
import { WorkloadType, WorkloadTypeName } from "../../models/workloads/WorkloadType";
import { useAppSelector } from "../../redux/hooks";
import { selectUserId, selectUserRole } from "../../redux/userInfo/userInfoSlice";
import UserService from "../../services/UserService";
import WorkloadService from "../../services/WorkloadService";
import { getCompareFunc, getSortSymbol } from "../../utils/sorting";

import generalStyles from "../../styles/general.module.scss";

interface CourseListCourse extends GetCourseDto {
   subject: Subject | "";
   workloadType: WorkloadType;
}

export default function ListCourses() {
   const [semester, setSemester] = useState(moment().year());
   const [courses, setCourses] = useState<CourseListCourse[]>([]);
   const [subject, setSubject] = useState<string>();
   const [student, setStudent] = useState<GetStudentUserDto>();
   const [sortProp, setSortProp] = useState<string>("title");
   const [sortMode, setSortMode] = useState<"asc" | "dsc">("asc");
   const role = useAppSelector(selectUserRole);
   const userId = useAppSelector(selectUserId);

   const onSubjectSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      setSubject(value ? value : undefined);
   };

   const changeElectiveCourse = (ev: React.MouseEvent, courseId: string, mode: "add" | "remove") => {
      ev.preventDefault();

      if (student && userId) {
         const userService = new UserService("beta");
         let elective_courses = student.elective_courses;

         if (mode === "add") {
            elective_courses = [...student.elective_courses, courseId];
         } else {
            elective_courses = student.elective_courses.filter(c => c !== courseId);
         }

         userService.patchStudent(userId, { elective_courses }).then(student => setStudent(student));
      }

      return false;
   };

   const toggleSorting = (ev: React.MouseEvent, property: string) => {
      ev.preventDefault();

      if (property === sortProp) {
         setSortMode(sortMode === "asc" ? "dsc" : "asc");
      } else {
         setSortMode("asc");
      }

      setSortProp(property);
      return false;
   };

   useEffect(() => {
      const workloadService = new WorkloadService("beta");
      workloadService.getWorkloads(semester, subject).then(workloads => {
         const reducedCourses = workloads
            .filter(w => w.type !== WorkloadType.GeneralMeetings)
            .reduce((a: CourseListCourse[], workload: GetWorkloadWithCoursesDto) => {
               return [
                  ...a,
                  ...workload.courses.map(c => {
                     return {
                        ...c,
                        subject: workload.subject,
                        workloadType: workload.type
                     };
                  })
               ];
            }, []);
         setCourses(reducedCourses);
      });
   }, [semester, subject]);

   useEffect(() => {
      if (userId && role === UserRole.Student) {
         const userService = new UserService("beta");
         userService.getStudent(userId).then(student => setStudent(student));
      }
   }, [userId, role]);

   return (
      <div className="container">
         <h2 className="text-center mb-3">Veranstaltungen</h2>
         <div className="d-flex justify-content-center align-items-center">
            <button
               type="button"
               className="btn btn-outline-secondary"
               disabled={semester <= 2021}
               onClick={() => setSemester(semester - 1)}
            >
               <i className="bi bi-chevron-left"></i>
            </button>
            <span className="mx-2">
               <b>
                  {semester}/{semester + 1}
               </b>
            </span>
            <button type="button" className="btn btn-outline-secondary" onClick={() => setSemester(semester + 1)}>
               <i className="bi bi-chevron-right"></i>
            </button>
         </div>
         <div className="row my-2">
            <div className="col offset-md-6 col-md-6 offset-xl-8 col-xl-4">
               <div className="input-group">
                  <span className="input-group-text" id="filter-label">
                     Filter
                  </span>
                  <select
                     className="form-select"
                     aria-describedby="filter-label"
                     aria-label="Filter"
                     id="filter"
                     name="filter"
                     onChange={onSubjectSelected}
                  >
                     <option key="" value=""></option>
                     {[...getAllSubjects()].map(subject => (
                        <option key={subject} value={subject}>
                           {SubjectName[subject]}
                        </option>
                     ))}
                  </select>
               </div>
            </div>
         </div>
         <div className="table-responsive">
            <table className="table table-striped text-nowrap">
               <thead>
                  <tr className={generalStyles.sortingHeader}>
                     <th scope="col" className="w-50 text-wrap">
                        <a href="/#" onClick={ev => toggleSorting(ev, "title")}>
                           Titel
                        </a>
                        {getSortSymbol("title", sortProp, sortMode)}
                     </th>
                     <th scope="col">
                        <a href="/#" onClick={ev => toggleSorting(ev, "subject")}>
                           Fach
                        </a>
                        {getSortSymbol("subject", sortProp, sortMode)}
                     </th>
                     <th scope="col">
                        <a href="/#" onClick={ev => toggleSorting(ev, "workloadType")}>
                           Lernort
                        </a>
                        {getSortSymbol("workloadType", sortProp, sortMode)}
                     </th>
                     {role === UserRole.Student && <th></th>}
                     {(role === UserRole.Admin || role === UserRole.LBZ || role === UserRole.Lecturer) && <th></th>}
                     {(role === UserRole.Admin || role === UserRole.LBZ) && (
                        <>
                           <th></th>
                           <th></th>
                        </>
                     )}
                  </tr>
               </thead>
               <tbody>
                  {courses.sort(getCompareFunc(sortProp, sortMode)).map(course => (
                     <tr key={course.id}>
                        <td className="w-50 text-wrap">
                           <Link to={`/courses/${course.id}`}>{course.title}</Link>
                        </td>
                        <td>{SubjectName[course.subject]}</td>
                        <td>{WorkloadTypeName[course.workloadType]}</td>
                        {role === UserRole.Student && (
                           <td className="align-middle">
                              {course.elective && student && student.elective_courses.includes(course.id) && (
                                 <button
                                    className="btn btn-danger btn-sm"
                                    onClick={ev => changeElectiveCourse(ev, course.id, "remove")}
                                 >
                                    aus Semesterplan entfernen
                                 </button>
                              )}
                              {course.elective && student && !student.elective_courses.includes(course.id) && (
                                 <button
                                    className="btn btn-success btn-sm"
                                    onClick={ev => changeElectiveCourse(ev, course.id, "add")}
                                 >
                                    zu Semesterplan hinzufügen
                                 </button>
                              )}
                           </td>
                        )}
                        {(role === UserRole.Admin || role === UserRole.LBZ || role === UserRole.Lecturer) && (
                           <td>
                              <Link to={`/courses/${course.id}/edit`}>
                                 <i className="bi-pencil" role="img" aria-label="Bearbeiten" title="Bearbeiten"></i>
                              </Link>
                           </td>
                        )}
                        {(role === UserRole.Admin || role === UserRole.LBZ) && (
                           <>
                              <td>
                                 <Link to={`/courses/${course.id}/copy`}>
                                    <i className="bi-files" role="img" aria-label="Kopieren" title="Kopieren"></i>
                                 </Link>
                              </td>
                              <td>
                                 <Link to={`/courses/${course.id}/delete`}>
                                    <i className="bi-trash" role="img" aria-label="Löschen" title="Löschen"></i>
                                 </Link>
                              </td>
                           </>
                        )}
                     </tr>
                  ))}
               </tbody>
            </table>
         </div>
      </div>
   );
}
