import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import navbarDE from "./locales/navbar_de.json";
import navbarEN from "./locales/navbar_en.json";
import personalMeetingsDE from "./locales/personal_meetings_de.json";
import personalMeetingsEN from "./locales/personal_meetings_en.json";

export const resources = {
   de: {
      navbar: navbarDE,
      personalMeetings: personalMeetingsDE
   },
   en: {
      navbar: navbarEN,
      personalMeetings: personalMeetingsEN
   }
} as const;

i18n
   // detect user language
   // learn more: https://github.com/i18next/i18next-browser-languageDetector
   .use(LanguageDetector)
   // pass the i18n instance to react-i18next.
   .use(initReactI18next)
   // init i18next
   // for all options read: https://www.i18next.com/overview/configuration-options
   .init({
      fallbackLng: "de",
      debug: true,
      interpolation: {
         escapeValue: false // not needed for react as it escapes by default
      },
      ns: ["navbar", "personalMeetings"],
      resources
   });

export default i18n;
