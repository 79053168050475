import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { RouteComponentProps, useHistory } from "react-router-dom";
import loginConfig from "../../config/loginConfig";
import { useAppDispatch } from "../../redux/hooks";
import { navigationLogin } from "../../redux/navigation/navigationSlice";
import { setUser } from "../../redux/userInfo/userInfoSlice";
import AuthService from "../../services/AuthService";
import schema, { Values } from "./Login.schema";

export default function Login(props: RouteComponentProps) {
   const authService = new AuthService("beta");

   const dispatch = useAppDispatch();
   const history = useHistory();

   const initialValues = {
      email: "",
      password: ""
   };

   const onSubmit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      const permissions = await authService.login(values.email, values.password);

      if (permissions) {
         dispatch(navigationLogin(permissions.role));
         dispatch(setUser(permissions));
         history.push(loginConfig[permissions.role]);
      }

      setSubmitting(false);
   };

   return (
      <div className="container">
         <h1 className="text-center">Login</h1>
         <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
               <Form>
                  <div className="mb-3">
                     <label htmlFor="email" className="form-label">
                        E-Mail Adresse:
                     </label>
                     <Field type="email" className="form-control" id="email" name="email" />
                     <ErrorMessage className="text-danger" name="email" component="div" />
                  </div>
                  <div className="mb-3">
                     <label htmlFor="password" className="form-label">
                        Passwort:
                     </label>
                     <Field type="password" className="form-control" id="password" name="password" />
                     <ErrorMessage className="text-danger" name="password" component="div" />
                  </div>
                  <div className="mb-3 row">
                     <div className="col">
                        <div className="float-end">
                           <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                              Anmelden
                           </button>
                        </div>
                     </div>
                  </div>
               </Form>
            )}
         </Formik>
      </div>
   );
}
