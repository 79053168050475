/* eslint no-unused-vars: "off" */
export enum MeetingType {
   Single = "single",
   Weekdays = "weekdays",
   Weekly = "weekly",
   Biweekly = "biweekly"
}

export const MeetingTypeName = {
   [MeetingType.Single]: "Einzeltermin",
   [MeetingType.Weekdays]: "Wochentags",
   [MeetingType.Weekly]: "Wöchentlich",
   [MeetingType.Biweekly]: "Zwei-Wöchentlich"
};
