import moment from "moment";
import { GetTimelineDto } from "../../models/timeline/get-timeline.dto";
import { WorkloadType } from "../../models/workloads/WorkloadType";
import TimelineActivity from "./TimelineActivity";

export default function getActivities(timeline: GetTimelineDto) {
   const activities: TimelineActivity[] = [];

   timeline.workloads.forEach(workload => {
      if (workload.courses.length > 0) {
         if (workload.start_date && workload.end_date && workload.type !== WorkloadType.GeneralMeetings) {
            activities.push({
               name: workload.title,
               id: `act_${workload.id}`,
               start: moment(workload.start_date).toDate().getTime(),
               end: moment(workload.end_date).toDate().getTime(),
               workloadType: workload.type,
               activityType: "workload",
               workloadId: workload.id
            });
         }

         workload.courses.forEach(course => {
            course.meetings.forEach(meeting => {
               activities.push({
                  name: meeting.title,
                  id: `act_${meeting.id}_${meeting.start_date}_${meeting.start_time}_${meeting.end_time}`,
                  start: moment(meeting.start_date).add(meeting.start_time, "hours").toDate().getTime(),
                  end: moment(meeting.end_date).add(meeting.end_time, "hours").toDate().getTime(),
                  workloadType: workload.type,
                  activityType: "meeting",
                  workloadId: workload.id,
                  courseId: course.id,
                  meetingId: meeting.id
               });
            });
         });
      }
   });

   timeline.personalMeetings.forEach(meeting => {
      activities.push({
         name: meeting.title,
         id: `act_${meeting.id}_${meeting.start_date}_${meeting.start_time}_${meeting.end_time}`,
         start: moment(meeting.start_date).add(meeting.start_time, "hours").toDate().getTime(),
         end: moment(meeting.end_date).add(meeting.end_time, "hours").toDate().getTime(),
         workloadType: "personal",
         activityType: "meeting",
         meetingId: meeting.id
      });
   });

   return activities;
}
