import { ErrorMessage, Field, FieldArray, Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { GetLecturerUserDto } from "../../models/users/get-lecturer-user.dto";
import { getAllSubjects, SubjectName } from "../../models/users/Subjects";
import UserService from "../../services/UserService";
import schema, { Values } from "./EditLecturer.schema";

interface RoutingParams {
   lecturerId?: string;
}

const initialValues: Values = {
   mail: "",
   subjects: []
};

export default function EditLecturer() {
   const { lecturerId } = useParams<RoutingParams>();
   const [formValues, setFormValues] = useState<Values>(initialValues);
   const [lecturer, setLecturer] = useState<GetLecturerUserDto>();
   const history = useHistory();

   useEffect(() => {
      if (lecturerId) {
         const userService = new UserService("beta");
         userService.getLecturer(lecturerId).then(lecturer => {
            setLecturer(lecturer);
            setFormValues({ ...lecturer });
         });
      }
   }, [lecturerId]);

   const onSubmit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      const userService = new UserService("beta");

      if (lecturerId) {
         await userService.patchLecturer(lecturerId, { subjects: values.subjects });
      } else {
         await userService.createLecturer(values);
      }

      history.push("/lecturers");

      setSubmitting(false);
   };

   return (
      <div className="container">
         <Formik initialValues={formValues} enableReinitialize={true} validationSchema={schema} onSubmit={onSubmit}>
            {({ isSubmitting, values }) => (
               <Form>
                  <div className="mb-3 row">
                     <h2>Lehrperson</h2>
                  </div>
                  {lecturer && (
                     <div className="mb-3 row">
                        <label htmlFor="displayName" className="col-sm-2 col-form-label fw-bold">
                           Anzeige-Name:
                        </label>
                        <div className="col-sm-10">
                           <input
                              type="text"
                              readOnly
                              className="form-control-plaintext"
                              id="displayName"
                              value={lecturer.display_name}
                           />
                        </div>
                     </div>
                  )}
                  {lecturer ? (
                     <div className="mb-3 row">
                        <label htmlFor="mail" className="col-sm-2 col-form-label fw-bold">
                           Mail:
                        </label>
                        <div className="col-sm-10">
                           <input
                              type="text"
                              readOnly
                              className="form-control-plaintext"
                              id="mail"
                              value={lecturer.mail}
                           />
                        </div>
                     </div>
                  ) : (
                     <div className="mb-3 row">
                        <label htmlFor="mail" className="col-sm-2 col-form-label fw-bold">
                           E-Mail:
                        </label>
                        <div className="col-sm-10">
                           <Field type="text" className="form-control" id="mail" name="mail" />
                           <ErrorMessage className="text-danger" name="mail" component="div" />
                        </div>
                     </div>
                  )}
                  <div className="mb-3 row">
                     <label htmlFor="subjects" className="col-sm-2 col-form-label fw-bold">
                        Fächer:
                     </label>
                     <div className="col-sm-10">
                        <FieldArray
                           name="subjects"
                           render={arrayHelpers => {
                              if (values.subjects && values.subjects.length > 0) {
                                 return values.subjects.map((subject, index) => (
                                    <div key={index} className="row mx-0 mb-3">
                                       <Field as="select" className="col form-select me-2" name={`subjects.${index}`}>
                                          <option value=""></option>
                                          {getAllSubjects().map(s => (
                                             <option key={s} value={s}>
                                                {SubjectName[s]}
                                             </option>
                                          ))}
                                       </Field>
                                       <button
                                          type="button"
                                          className="col-auto btn btn-danger me-2"
                                          onClick={() => arrayHelpers.remove(index)}
                                       >
                                          Entfernen
                                       </button>
                                       <button
                                          type="button"
                                          className="col-auto btn btn-success"
                                          onClick={() => arrayHelpers.insert(index, "")}
                                       >
                                          Einfügen
                                       </button>
                                    </div>
                                 ));
                              } else {
                                 return (
                                    <button
                                       type="button"
                                       className="btn btn-success"
                                       onClick={() => arrayHelpers.push("")}
                                    >
                                       Fach hinzufügen
                                    </button>
                                 );
                              }
                           }}
                        />
                        <ErrorMessage className="text-danger" name="lecturers" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <div className="col">
                        <div className="float-end">
                           <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                              Speichern
                           </button>
                        </div>
                     </div>
                  </div>
               </Form>
            )}
         </Formik>
      </div>
   );
}
