import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetTimelineDto } from "../../models/timeline/get-timeline.dto";
import TimetableService from "../../services/TimetableService";
import type { RootState } from "../store";

interface TimetableState {
   timetable?: GetTimelineDto;
}

const initialState: TimetableState = {
   timetable: undefined
};

export const loadTimetable = createAsyncThunk("timetable/loadTimetable", async () => {
   const timetableService = new TimetableService("beta");
   const timetable = await timetableService.getTimetable();
   return timetable;
});

export const timetableSlice = createSlice({
   name: "timetable",
   initialState,
   reducers: {
      setTimetable: (state, action: PayloadAction<GetTimelineDto>) => {
         state.timetable = action.payload;
      }
   },
   extraReducers: builder => {
      builder.addCase(loadTimetable.fulfilled, (state, action) => {
         state.timetable = action.payload;
      });
   }
});

export const { setTimetable } = timetableSlice.actions;

export const selectTimetable = (state: RootState) => state.timetable.timetable;

export default timetableSlice.reducer;
