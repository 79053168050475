import { UserRole } from "../models/users/UserRole";

const loginConfig = {
   [UserRole.Admin]: "/workloads",
   [UserRole.LBZ]: "/workloads",
   [UserRole.Lecturer]: "/courses",
   [UserRole.Student]: "/plan",
   [UserRole.None]: "/not_found"
};

export default loginConfig;
