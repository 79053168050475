import * as Yup from "yup";
import { Subject } from "../../models/users/Subjects";
import { WorkloadType } from "../../models/workloads/WorkloadType";

export interface Values {
   title: string;
   subject: Subject;
   semester: string;
   type: WorkloadType;
}

const schema = Yup.object().shape({
   title: Yup.string().required("Erforderliches Feld"),
   subject: Yup.mixed<string>().oneOf(Object.values(Subject)).required("Erforderliches Feld"),
   semester: Yup.number().min(2021).required("Erforderliches Feld"),
   type: Yup.mixed<string>().oneOf(Object.values(WorkloadType)).required("Erforderliches Feld")
});

export default schema;
