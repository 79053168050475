import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { GetMeetingDto } from "../../../models/meetings/get-meeting.dto";
import { MeetingTypeName } from "../../../models/meetings/meetingType";
import { UserRole } from "../../../models/users/UserRole";
import { useAppSelector } from "../../../redux/hooks";
import { selectUserRole } from "../../../redux/userInfo/userInfoSlice";
import CourseService from "../../../services/CourseService";

interface RoutingParams {
   courseId: string;
   meetingId: string;
}

export default function DisplayCourseMeeting() {
   const { courseId, meetingId } = useParams<RoutingParams>();
   const [meeting, setMeeting] = useState<GetMeetingDto | undefined>(undefined);
   const role = useAppSelector(selectUserRole);

   useEffect(() => {
      const courseService = new CourseService("beta");
      courseService.getMeeting(courseId, meetingId).then(meeting => setMeeting(meeting));
   }, [courseId, meetingId]);

   if (meeting) {
      return (
         <div className="container">
            <div className="mb-3 row">
               <h2>Veranstaltungstermin</h2>
            </div>
            <div className="mb-3 row">
               <label htmlFor="title" className="col-sm-2 col-form-label fw-bold">
                  Titel:
               </label>
               <div className="col-sm-10">
                  <input type="text" readOnly className="form-control-plaintext" id="title" value={meeting.title} />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="startDate" className="col-sm-2 col-form-label fw-bold">
                  Start-Datum:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="startDate"
                     value={moment(meeting.start_date).format("DD.MM.YYYY")}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="endDate" className="col-sm-2 col-form-label fw-bold">
                  End-Datum:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="endDate"
                     value={moment(meeting.end_date).format("DD.MM.YYYY")}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="startTime" className="col-sm-2 col-form-label fw-bold">
                  Start-Zeit:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="startTime"
                     value={meeting.start_time}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="endTime" className="col-sm-2 col-form-label fw-bold">
                  End-Zeit:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="endTime"
                     value={meeting.end_time}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="type" className="col-sm-2 col-form-label fw-bold">
                  Meeting-Typ:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="type"
                     value={MeetingTypeName[meeting.type]}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="information" className="col-sm-2 col-form-label fw-bold">
                  Information:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="information"
                     value={meeting.information}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="link" className="col-sm-2 col-form-label fw-bold">
                  Link:
               </label>
               <div className="col-sm-10">
                  {meeting.link && (
                     <a href={meeting.link} target="_blank" rel="noreferrer">
                        {meeting.link}
                     </a>
                  )}
               </div>
            </div>
            <div className="mb-3 row">
               <label htmlFor="attendees" className="col-sm-2 col-form-label fw-bold">
                  Teilnehmende:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="attendees"
                     value={meeting.attendees}
                  />
               </div>
            </div>
            {meeting.canceledDates && meeting.canceledDates.length > 0 && (
               <div className="mb-3 row">
                  <label htmlFor="canceledDates" className="col-sm-2 col-form-label fw-bold">
                     Abgesagte Termine:
                  </label>
                  <div className="col-sm-10">
                     {meeting.canceledDates.map(date => (
                        <input
                           type="text"
                           readOnly
                           className="form-control-plaintext"
                           id="canceledDates"
                           value={moment(date).format("DD.MM.YYYY")}
                        />
                     ))}
                  </div>
               </div>
            )}
            <div className="mb-3 row">
               <label htmlFor="creator" className="col-sm-2 col-form-label fw-bold">
                  Ersteller*in:
               </label>
               <div className="col-sm-10">
                  <input
                     type="text"
                     readOnly
                     className="form-control-plaintext"
                     id="creator"
                     value={meeting.creator.display_name}
                  />
               </div>
            </div>
            <div className="mb-3 row">
               <div className="col">
                  <div className="float-end">
                     {(role === UserRole.Admin || role === UserRole.LBZ || role === UserRole.Lecturer) && (
                        <>
                           <Link
                              to={`/courses/${courseId}/meetings/${meetingId}/delete`}
                              className="btn btn-danger me-1"
                           >
                              Löschen
                           </Link>
                           <Link to={`/courses/${courseId}/meetings/${meetingId}/edit`} className="btn btn-primary">
                              Bearbeiten
                           </Link>
                        </>
                     )}
                  </div>
               </div>
            </div>
         </div>
      );
   } else {
      return null;
   }
}
