import { HashRouter, Route, Switch } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Navbar from "./components/Navbar/Navbar";
import routes from "./router/routes";

import "./styles/bootstrap.custom.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./services/axios.interceptors";

export default function App() {
   return (
      <HashRouter>
         <Navbar />
         <Switch>
            {routes.map((route, i) => (
               <Route
                  key={i}
                  path={route.path}
                  exact={route.exact}
                  render={props => (
                     <Layout breadcrumbs={route.breadcrumbs(props)}>
                        <route.component {...props} {...route.props} />
                     </Layout>
                  )}
               />
            ))}
         </Switch>
      </HashRouter>
   );
}
