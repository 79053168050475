import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { loadTimetable, selectTimetable } from "../../redux/timetable/timetableSlice";

export default function TimeTable() {
   const dispatch = useAppDispatch();
   const timetableData = useAppSelector(selectTimetable);
   const [collapsed, setCollapsed] = useState<{ [key: string]: boolean }>({});

   const toggleWorkload = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>, workloadId: string) => {
      ev.preventDefault();

      if (!Object.keys(collapsed).includes(workloadId)) {
         setCollapsed({
            ...collapsed,
            [workloadId]: true
         });
      } else {
         setCollapsed({
            ...collapsed,
            [workloadId]: !collapsed[workloadId]
         });
      }

      return false;
   };

   const getDurationProgress = (start: string, end: string) => {
      const current = new Date();
      const startDate = new Date(start);
      const endDate = new Date(end);

      if (current < startDate) {
         return 0;
      } else if (endDate < current) {
         return 100;
      } else {
         return ((current.getTime() - startDate.getTime()) * 100) / (endDate.getTime() - startDate.getTime());
      }
   };

   useEffect(() => {
      if (!timetableData) {
         dispatch(loadTimetable());
      }
   });

   return (
      <div className="container-fluid">
         <div className="table-responsive-md">
            <table className="table table-striped text-nowrap align-top">
               <thead>
                  <tr>
                     <th></th>
                     <th scope="col" className="w-50 text-wrap">
                        Veranstaltung
                     </th>
                     <th scope="col">Dauer</th>
                     <th scope="col">Dauer verbleibend</th>
                     <th scope="col">Start</th>
                     <th scope="col">Ende</th>
                  </tr>
               </thead>
               {timetableData && (
                  <tbody>
                     {timetableData.workloads.map(workload => {
                        if (workload.courses.length > 0) {
                           const durationProgress = getDurationProgress(workload.start_date, workload.end_date);

                           return (
                              <React.Fragment key={workload.id}>
                                 <tr>
                                    <td>
                                       <a href="/#" onClick={ev => toggleWorkload(ev, workload.id)}>
                                          <i
                                             className={`bi bi-chevron-${collapsed[workload.id] ? "down" : "up"} ms-1`}
                                          ></i>
                                       </a>
                                    </td>
                                    <td className="w-50 text-wrap">
                                       <b>{workload.title}</b>
                                    </td>
                                    <td>
                                       {workload.start_date && workload.end_date && (
                                          <span>
                                             {moment(workload.end_date).diff(moment(workload.start_date), "days")} Tage
                                          </span>
                                       )}
                                    </td>
                                    <td>
                                       {workload.start_date && workload.end_date && (
                                          <div className="row">
                                             <div className="col-6 col-lg-7 col-xl-9">
                                                <div className="progress mt-1">
                                                   <div
                                                      className="progress-bar"
                                                      role="progressbar"
                                                      style={{
                                                         width: `${durationProgress}%`
                                                      }}
                                                      aria-valuenow={durationProgress}
                                                      aria-valuemin={0}
                                                      aria-valuemax={100}
                                                   ></div>
                                                </div>
                                             </div>
                                             <div className="col-6 col-lg-5 col-xl-3">
                                                {`${durationProgress.toFixed(0)} %`}
                                             </div>
                                          </div>
                                       )}
                                    </td>
                                    <td>
                                       {workload.start_date ? moment(workload.start_date).format("DD.MM.YYYY") : ""}
                                    </td>
                                    <td>{workload.end_date ? moment(workload.end_date).format("DD.MM.YYYY") : ""}</td>
                                 </tr>
                                 {!collapsed[workload.id] &&
                                    workload.courses.map(course => (
                                       <tr key={course.id}>
                                          <td></td>
                                          <td className="w-50 text-wrap">
                                             <Link to={`/courses/${course.id}`}>{course.title}</Link>
                                          </td>
                                          <td>
                                             {course.start_date && course.end_date && (
                                                <>{moment(course.end_date).diff(moment(course.start_date), "d")} Tage</>
                                             )}
                                          </td>
                                          <td></td>
                                          <td>
                                             {course.start_date ? moment(course.start_date).format("DD.MM.YYYY") : ""}
                                          </td>
                                          <td>{course.end_date ? moment(course.end_date).format("DD.MM.YYYY") : ""}</td>
                                       </tr>
                                    ))}
                              </React.Fragment>
                           );
                        } else {
                           return null;
                        }
                     })}
                  </tbody>
               )}
            </table>
         </div>
      </div>
   );
}
