import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import type { RootState } from "../store";

interface ErrorsState {
   errors: { text: string; key: string }[];
}

const initialState: ErrorsState = {
   errors: []
};

export const errorsSlice = createSlice({
   name: "errors",
   initialState,
   reducers: {
      addError: (state, action: PayloadAction<string>) => {
         state.errors = [...state.errors, { text: action.payload, key: uuidv4() }];
      },
      removeError: (state, action: PayloadAction<string>) => {
         state.errors = state.errors.filter(e => e.key !== action.payload);
      },
      clearErrors: state => {
         state.errors = [];
      }
   }
});

export const { addError, removeError, clearErrors } = errorsSlice.actions;

export const selectErrorItems = (state: RootState) => state.errors.errors;

export default errorsSlice.reducer;
