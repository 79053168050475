import { GetTimelineDto } from "../../models/timeline/get-timeline.dto";
import { WorkloadType } from "../../models/workloads/WorkloadType";
import TimelineReservation from "./TimelineReservation";

export default function getReservations(timeline: GetTimelineDto) {
   const reservations: TimelineReservation[] = [];

   timeline.workloads.forEach(workload => {
      if (workload.start_date && workload.end_date && workload.type !== WorkloadType.GeneralMeetings) {
         reservations.push({
            activity: `act_${workload.id}`,
            resource: `res_${workload.id}`
         });
      }

      workload.courses.forEach(course => {
         course.meetings.forEach(meeting => {
            if (workload.type !== WorkloadType.GeneralMeetings) {
               reservations.push({
                  activity: `act_${meeting.id}_${meeting.start_date}_${meeting.start_time}_${meeting.end_time}`,
                  resource: `res_${course.id}`
               });
            } else {
               reservations.push({
                  activity: `act_${meeting.id}_${meeting.start_date}_${meeting.start_time}_${meeting.end_time}`,
                  resource: `res_${workload.id}`
               });
            }
         });
      });
   });

   timeline.personalMeetings.forEach(meeting => {
      reservations.push({
         activity: `act_${meeting.id}_${meeting.start_date}_${meeting.start_time}_${meeting.end_time}`,
         resource: "res_pers_meetings"
      });
   });

   return reservations;
}
