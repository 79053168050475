import { GetTimelineDto } from "../models/timeline/get-timeline.dto";
import BaseService from "./BaseService";

export default class TimetableService extends BaseService {
   constructor(version: string) {
      super(version, "timetable");
   }

   public async getTimetable(): Promise<GetTimelineDto> {
      return this.get("");
   }
}
