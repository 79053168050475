import { useAppDispatch } from "../../redux/hooks";
import { navigationLogout } from "../../redux/navigation/navigationSlice";
import { unsetUser } from "../../redux/userInfo/userInfoSlice";

export default function Logout() {
   const dispatch = useAppDispatch();

   dispatch(navigationLogout());
   dispatch(unsetUser());

   window.location.href = "/api/vbeta/auth/logout";

   return null;
}
