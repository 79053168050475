import { CreateLBZUserDto } from "../models/users/create-lbz-user.dto";
import { CreateLecturerDto } from "../models/users/create-lecturer.dto";
import { CreateStudentDto } from "../models/users/create-student.dto";
import { GetLecturerUserDto } from "../models/users/get-lecturer-user.dto";
import { GetStudentUserDto } from "../models/users/get-student-user.dto";
import { GetUserDto } from "../models/users/get-user.dto";
import { PatchLecturerDto } from "../models/users/patch-lecturer.dto";
import { PatchStudentDto } from "../models/users/patch-student.dto";
import { PatchUserStatusDto } from "../models/users/patch-user-status.dto";
import BaseService from "./BaseService";

export default class UserService extends BaseService {
   constructor(version: string) {
      super(version, "users");
   }

   public async patchUserStatus(userId: string, data: PatchUserStatusDto): Promise<void> {
      return this.patch(`${userId}/status`, data);
   }

   public async createStudent(data: CreateStudentDto): Promise<GetStudentUserDto> {
      return this.post("students", data);
   }

   public async getStudent(userId: string): Promise<GetStudentUserDto> {
      return this.get(`students/${userId}`);
   }

   public async patchStudent(userId: string, data: PatchStudentDto): Promise<GetStudentUserDto> {
      return this.patch(`students/${userId}`, data);
   }

   public async getLecturers(filter?: string): Promise<GetLecturerUserDto[]> {
      return this.get(`lecturers${filter ? "?filter=" + filter : ""}`);
   }

   public async getLecturer(lecturerId: string): Promise<GetLecturerUserDto> {
      return this.get(`lecturers/${lecturerId}`);
   }

   public async createLecturer(data: CreateLecturerDto): Promise<GetLecturerUserDto> {
      return this.post("lecturers", data);
   }

   public async patchLecturer(lecturerId: string, data: PatchLecturerDto): Promise<GetLecturerUserDto> {
      return this.patch(`lecturers/${lecturerId}`, data);
   }

   public async getLBZ(): Promise<GetUserDto[]> {
      return this.get("lbz");
   }

   public async createLBZ(data: CreateLBZUserDto): Promise<GetUserDto> {
      return this.post("lbz", data);
   }
}
