import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { CreateStudentDto } from "../../models/users/create-student.dto";
import { getDefaultSubject, getSubjects, Subject, SubjectName } from "../../models/users/Subjects";
import UserService from "../../services/UserService";
import schema, { Values } from "./Register.schema";

export default function Register(props: RouteComponentProps) {
   const userService = new UserService("beta");

   const history = useHistory();

   const initialValues = {
      mail: "",
      password: "",
      display_name: "",
      subjects: [getDefaultSubject(), getDefaultSubject()]
   };

   const onSubmit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      const student: CreateStudentDto = {
         ...values,
         subjects: [...values.subjects, Subject.BWS, Subject.DaZ],
         semester: moment().year()
      };

      await userService.createStudent(student);

      history.push("/login");

      setSubmitting(false);
   };

   return (
      <div className="container">
         <h1 className="text-center">Registrierung</h1>
         <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
               <Form>
                  <div className="mb-3 row">
                     <label htmlFor="mail" className="col-sm-2 col-form-label">
                        E-Mail Adresse:
                     </label>
                     <div className="col-sm-10">
                        <Field type="mail" className="form-control" id="mail" name="mail" />
                        <ErrorMessage className="text-danger" name="mail" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="password" className="col-sm-2 col-form-label">
                        Passwort:
                     </label>
                     <div className="col-sm-10">
                        <Field type="password" className="form-control" id="password" name="password" />
                        <ErrorMessage className="text-danger" name="password" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="display_name" className="col-sm-2 col-form-label">
                        Anzeige-Name:
                     </label>
                     <div className="col-sm-10">
                        <Field type="text" className="form-control" id="display_name" name="display_name" />
                        <ErrorMessage className="text-danger" name="display_name" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="subjects[0]" className="col-sm-2 col-form-label">
                        1. Fach
                     </label>
                     <div className="col-sm-10">
                        <Field as="select" className="form-select" id="subjects[0]" name="subjects[0]">
                           {getSubjects().map(subject => (
                              <option key={`subject_0_${subject}`} value={subject}>
                                 {SubjectName[subject]}
                              </option>
                           ))}
                        </Field>
                        <ErrorMessage className="text-danger" name="subjects[0]" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="subjects[1]" className="col-sm-2 col-form-label">
                        2. Fach
                     </label>
                     <div className="col-sm-10">
                        <Field as="select" className="form-select" id="subjects[1]" name="subjects[1]">
                           {getSubjects().map(subject => (
                              <option key={`subject_1_${subject}`} value={subject}>
                                 {SubjectName[subject]}
                              </option>
                           ))}
                        </Field>
                        <ErrorMessage className="text-danger" name="subjects[1]" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <div className="col">
                        <div className="float-end">
                           <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                              Erstellen
                           </button>
                        </div>
                     </div>
                  </div>
               </Form>
            )}
         </Formik>
      </div>
   );
}
