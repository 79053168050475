import { useHistory, useParams } from "react-router-dom";
import CourseService from "../../services/CourseService";

interface RoutingParams {
   courseId: string;
}

export default function DeleteCourse() {
   const { courseId } = useParams<RoutingParams>();
   const history = useHistory();

   const submit = () => {
      const courseService = new CourseService("beta");
      courseService.getCourse(courseId).then(course => {
         courseService.deleteCourse(courseId).then(() => history.push(`/workloads/${course.workload}`));
      });
   };

   return (
      <div className="container">
         <h2 className="text-center mb-3">Löschen bestätigen</h2>
         <div className="text-center pt-3">
            <button type="button" className="btn btn-danger" onClick={() => submit()}>
               Löschen
            </button>
         </div>
      </div>
   );
}
