import { CreateMeetingDto } from "../models/meetings/create-meeting.dto";
import { GetMeetingDto } from "../models/meetings/get-meeting.dto";
import { PatchMeetingDto } from "../models/meetings/patch-meeting.dto";
import BaseService from "./BaseService";

export default class PersonalMeetingService extends BaseService {
   constructor(version: string) {
      super(version, "personal-meetings");
   }

   public getPersonalMeetings(): Promise<GetMeetingDto[]> {
      return this.get("");
   }

   public getPersonalMeeting(meetingId: string): Promise<GetMeetingDto> {
      return this.get(`${meetingId}`);
   }

   public createPersonalMeeting(data: CreateMeetingDto): Promise<GetMeetingDto> {
      return this.post("", data);
   }

   public patchPersonalMeeting(meetingId: string, data: PatchMeetingDto): Promise<GetMeetingDto> {
      return this.patch(`${meetingId}`, data);
   }

   public deletePersonalMeeting(meetingId: string): Promise<void> {
      return this.delete(`${meetingId}`);
   }
}
