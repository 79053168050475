import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import UserPermissions from "../../models/users/UserPermissions";
import { UserRole } from "../../models/users/UserRole";
import type { RootState } from "../store";

interface UserInfoState {
   userId: string;
   userRole: UserRole;
}

const initialState: UserInfoState = {
   userId: "",
   userRole: UserRole.None
};

export const userInfoSlice = createSlice({
   name: "userInfo",
   initialState,
   reducers: {
      setUser: (state, action: PayloadAction<UserPermissions>) => {
         state.userId = action.payload.userId;
         state.userRole = action.payload.role;
      },
      unsetUser: state => {
         state = initialState;
      }
   }
});

export const { setUser, unsetUser } = userInfoSlice.actions;

export const selectUserRole = (state: RootState) => state.userInfo.userRole;
export const selectUserId = (state: RootState) => state.userInfo.userId;

export default userInfoSlice.reducer;
