import * as Yup from "yup";
import { Subject } from "../../models/users/Subjects";

export interface Values {
   mail: string;
   subjects: Subject[];
}

const schema = Yup.object().shape({
   mail: Yup.string().email().required("Erforderliches Feld"),
   subjects: Yup.array().of(Yup.mixed<string>().oneOf(Object.values(Subject)).required("Erforderliches Feld"))
});

export default schema;
