import { ErrorMessage, Field, FieldArray, Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { GetCourseWithLecturersDto } from "../../models/courses/get-course-with-lecturers.dto";
import { GetLecturerUserDto } from "../../models/users/get-lecturer-user.dto";
import { UserRole } from "../../models/users/UserRole";
import { useAppSelector } from "../../redux/hooks";
import { selectUserRole } from "../../redux/userInfo/userInfoSlice";
import CourseService from "../../services/CourseService";
import UserService from "../../services/UserService";
import schema, { Values } from "./EditCourse.schema";

interface RoutingParams {
   workloadId?: string;
   courseId?: string;
}

interface EditCourseProps {
   create?: boolean;
}

const initialValues: Values = {
   title: "",
   information: "",
   elective: false,
   link: undefined,
   workload: "",
   lecturers: []
};

export default function EditCourse(props: EditCourseProps) {
   const { workloadId, courseId } = useParams<RoutingParams>();
   const [courseDto, setCourseDto] = useState<GetCourseWithLecturersDto | undefined>();
   const [course, setCourse] = useState<Values>({ ...initialValues, workload: workloadId || "" });
   const [allLecturers, setAllLecturers] = useState<GetLecturerUserDto[]>([]);
   const history = useHistory();
   const role = useAppSelector(selectUserRole);

   useEffect(() => {
      if (courseId) {
         const courseService = new CourseService("beta");
         courseService.getCourse(courseId).then(course => {
            setCourse({
               ...course,
               lecturers: course.lecturers.map(l => l.id)
            });
            setCourseDto(course);
         });
      }
   }, [courseId]);

   useEffect(() => {
      if (role === UserRole.Admin || role === UserRole.LBZ) {
         const userService = new UserService("beta");
         userService.getLecturers().then(lecturers => setAllLecturers(lecturers.filter(l => l.active)));
      }
   }, [role]);

   const onSubmit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      const courseService = new CourseService("beta");

      // clear if empty to bypass url validation on empty string
      values.link = values.link ? values.link : undefined;

      if (courseId) {
         await courseService.patchCourse(courseId, values);
         history.push(`/courses/${courseId}`);
      } else {
         const createdCourse = await courseService.createCourse(values);
         history.push(`/courses/${createdCourse.id}`);
      }

      setSubmitting(false);
   };

   return (
      <div className="container">
         <Formik initialValues={course} enableReinitialize={true} validationSchema={schema} onSubmit={onSubmit}>
            {({ isSubmitting, values }) => (
               <Form>
                  <div className="mb-3 row">
                     <h2>Veranstaltung</h2>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="title" className="col-sm-2 col-form-label fw-bold">
                        Titel:
                     </label>
                     <div className="col-sm-10">
                        <Field type="text" className="form-control" id="title" name="title" />
                        <ErrorMessage className="text-danger" name="title" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="elective" className="col-sm-2 col-form-label fw-bold">
                        Wahl erforderlich:
                     </label>
                     <div className="col-sm-10">
                        <Field type="checkbox" className="form-check-input" id="elective" name="elective" />
                        <ErrorMessage className="text-danger" name="elective" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="information" className="col-sm-2 col-form-label fw-bold">
                        Information:
                     </label>
                     <div className="col-sm-10">
                        <Field as="textarea" className="form-control" id="information" name="information" />
                        <ErrorMessage className="text-danger" name="information" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="link" className="col-sm-2 col-form-label fw-bold">
                        Link:
                     </label>
                     <div className="col-sm-10">
                        <Field className="form-control" id="link" name="link" />
                        <ErrorMessage className="text-danger" name="link" component="div" />
                     </div>
                  </div>
                  <div className="mb-3 row">
                     <label htmlFor="lecturers" className="col-sm-2 col-form-label fw-bold">
                        Lehrpersonen:
                     </label>
                     {(role === UserRole.Admin || role === UserRole.LBZ) && (
                        <div className="col-sm-10">
                           <FieldArray
                              name="lecturers"
                              render={arrayHelpers => {
                                 if (values.lecturers && values.lecturers.length > 0) {
                                    return (
                                       <>
                                          {values.lecturers.map((lecturer, index) => (
                                             <div key={index} className="row mx-0 mb-3">
                                                <Field
                                                   as="select"
                                                   className="col form-select me-2"
                                                   name={`lecturers.${index}`}
                                                >
                                                   <option value=""></option>
                                                   {allLecturers.map(l => (
                                                      <option key={l.id} value={l.id}>
                                                         {l.display_name}
                                                      </option>
                                                   ))}
                                                </Field>
                                                <button
                                                   type="button"
                                                   className="col-auto btn btn-danger"
                                                   onClick={() => arrayHelpers.remove(index)}
                                                >
                                                   Entfernen
                                                </button>
                                             </div>
                                          ))}
                                          <div className="row mx-0 mb-3">
                                             <button
                                                type="button"
                                                className="col-auto btn btn-success"
                                                onClick={() => arrayHelpers.insert(values.lecturers.length, "")}
                                             >
                                                weitere Lehrperson hinzufügen
                                             </button>
                                          </div>
                                       </>
                                    );
                                 } else {
                                    return (
                                       <button
                                          type="button"
                                          className="btn btn-success"
                                          onClick={() => arrayHelpers.push("")}
                                       >
                                          Lehrperson hinzufügen
                                       </button>
                                    );
                                 }
                              }}
                           />
                           <ErrorMessage className="text-danger" name="lecturers" component="div" />
                        </div>
                     )}
                     {role === UserRole.Lecturer && courseDto && (
                        <div className="col-sm-10">
                           {courseDto.lecturers.map(lecturer => (
                              <a key={lecturer.id} href={`mailto:${lecturer.mail}`}>
                                 {lecturer.display_name}
                              </a>
                           ))}
                        </div>
                     )}
                  </div>
                  <div className="mb-3 row">
                     <div className="col">
                        <div className="float-end">
                           {courseId && (role === UserRole.Admin || role === UserRole.LBZ) && (
                              <Link to={`/courses/${courseId}/delete`} className="btn btn-danger me-1">
                                 Löschen
                              </Link>
                           )}
                           <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                              Speichern
                           </button>
                        </div>
                     </div>
                  </div>
               </Form>
            )}
         </Formik>
      </div>
   );
}
