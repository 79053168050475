export const getCompareFunc = (key: string, mode: "asc" | "dsc") => {
   if (mode === "asc") {
      return (a: any, b: any) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
   } else {
      return (a: any, b: any) => (a[key] > b[key] ? -1 : b[key] > a[key] ? 1 : 0);
   }
};

export const getSortSymbol = (property: string, sortProperty: string, mode: "asc" | "dsc") => {
   if (property !== sortProperty) {
      return null;
   } else {
      if (mode === "asc") {
         return (
            <i
               className="bi bi-arrow-down"
               role="img"
               aria-label="Aufsteigend sortieren"
               title="Aufsteigend sortieren"
            ></i>
         );
      } else {
         return (
            <i className="bi bi-arrow-up" role="img" aria-label="Absteigend sortieren" title="Absteigend sortieren"></i>
         );
      }
   }
};
